import { HeartIcon as HeartIconOutline, MapPinIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import calcDiffInDays from '../functions/calcDiffInDays'
import createDateDiffText from '../functions/createDateDiffText'
import createPollDateBubble from '../functions/createPollDateBubble'
import createZoneTypeBubble from '../functions/createZoneTypeBubble'
import formatAssetName from '../functions/formatAssetName'
import formatLastPolledTime from '../functions/formatLastPolledTime'
import formatSiteDistance from '../functions/formatSiteDistance'
import MapLink from './MapLink'
import PanelContainer from './PanelContainer'
import Table from './Table/Table'
import TableBody from './Table/TableBody'
import TableCell from './Table/TableCell'
import TableHeader from './Table/TableHeader'
import TableHeading from './Table/TableHeading'
import TableRow from './Table/TableRow'
import FavouriteAssetButton from './Table/Controls/FavouriteAssetButton'
import { useQueryClient } from '@tanstack/react-query'
import { useDebouncedCallback } from 'use-debounce'

export default function AssetSearchResultsTable({ results }) {
  const queryClient = useQueryClient()
  const invalidateQueriesCallback = useDebouncedCallback(() => queryClient.invalidateQueries(['assets']), 1000)

  return (
    <PanelContainer>
      <Table>
        <TableHeader>
          <TableHeading className='w-[5rem]'>STATUS</TableHeading>
          <TableHeading>PALLET</TableHeading>
          <TableHeading>LAST PING</TableHeading>
          <TableHeading><span className='flex'>LAST LOCATION <MapPinIcon className='w-6 h-6 ml-3' /></span></TableHeading>
          <TableHeading className='w-8'> </TableHeading>
          <TableHeading>IN A ZONE</TableHeading>
          <TableHeading><HeartIconOutline className='w-7 h-7 ml-auto' /></TableHeading>
        </TableHeader>

        <TableBody>
          {results.map((result, key) => {
            const diff = calcDiffInDays(result.last_update_time)
            const assetName = formatAssetName(result.device_name)

            return (
              <TableRow key={`sr-${key}`}>
                <TableCell className='text-center'>{createPollDateBubble(diff)}</TableCell>
                <TableCell><Link to={`/reports/asset-history/${result.device_id}`} className='underline'>{assetName}</Link></TableCell>
                <TableCell>
                  {isNaN(diff)
                    ? 'N/A'
                    : <>{formatLastPolledTime(result.last_update_time)}<br />{createDateDiffText(diff)}</>
                  }
                </TableCell>
                <TableCell><MapLink latitude={result.latitude} longitude={result.longitude} name={assetName} text={result.address} /></TableCell>
                <TableCell>{createZoneTypeBubble(result.is_in_zone, result.is_third_party_zone)}</TableCell>
                <TableCell>{result.nearest_site_name ?? 'N/A'}<br />{formatSiteDistance(result.nearest_site_dist)}</TableCell>
                <TableCell className='text-right'>
                  <FavouriteAssetButton
                    asset={result}
                    onMutationSuccess={() => invalidateQueriesCallback()}
                  />
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </PanelContainer>
  )
}
