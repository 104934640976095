import { Bars3Icon } from '@heroicons/react/24/outline'
import useShowNav from '../../Hooks/useShowNav'

export default function ToggleMenuButton() {
  const { showNav, toggleShowNav } = useShowNav()

  return (
    <div className='md:hidden'>
      <span
        className='cursor-pointer'
        onClick={toggleShowNav}
        title={`${showNav ? 'Hide the sidebar' : 'Show the sidebar'}`}
      >
        <Bars3Icon className='w-6 h-6' />
      </span>
    </div>
  )
}
