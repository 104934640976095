import { MapPinIcon } from '@heroicons/react/24/outline'
import DashboardCard from '../DashboardCard'

export default function InZoneCard({ value, showIcon, percentage }) {
  return (
    <DashboardCard
      icon={showIcon ? <MapPinIcon /> : null}
      value={value}
      description='Pallets in zone'
      backgroundColour='bg-white'
      valueColour='text-success'
      textColour='text-black'
      buttonColour='bg-shady-lady'
      percentage={percentage}
      url='/reports/search?inZone=true'
    />
  )
}
