import usePageTitle from '../Hooks/usePageTitle'

export default function PageHeader() {
  const { title, subtitle } = usePageTitle()

  // Don't show anything if we're not requesting at least a title
  if (title === '') {
    return null
  }

  return (
    <div>
      <p className='text-2xl font-semibold font-lato'>{title}</p>
      {subtitle && <p className='font-lato'>{subtitle}</p>}
    </div>
  )
}
