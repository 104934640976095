import AssetsByOrganisationCard from './AssetsByOrganisationCard'

export default function PolledWithin30Days({ siteId, value }) {
  return (
    <AssetsByOrganisationCard
      value={value}
      description='Polled within 30 days'
      colour='text-warning'
      url={`/reports/search?pingLast30Days=true&site=${siteId}`}
    />
  )
}
