import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import ResultsPanel from '../../components/AssetTimeline/ResultsPanel'
import LoadingSpinner from '../../components/LoadingSpinner'
import ZoneMonitorBubbleKey from '../../components/ZoneMonitor/ZoneMonitorBubbleKey'
import useRepository from '../../Hooks/useRepository'
import ReportRepository from '../../Repository/ReportRepository'
import useBreadcrumbs from '../../Hooks/useBreadcrumbs'
import usePageTitle from '../../Hooks/usePageTitle'

export default function AssetTimelinePage() {
  const reportRepository = useRepository(ReportRepository)
  const [days, setDays] = useState(7)
  const [page, setPage] = useState(1)

  const { data, isLoading, isError } = useQuery({
    queryKey: ['asset-timeline', days, page],
    queryFn: () => reportRepository.loadAssetTimeline(days, page)
  })

  useBreadcrumbs([
    { label: 'Report menu', link: '/reports' },
    { label: 'Asset Timeline', link: '/reports/asset-timeline' }
  ])

  usePageTitle(
    'Pallet movements timeline',
    'Pallet movements across a defined period of time. Hold your mouse over each item for poll location to appear'
  )

  return <>
    <div className='w-full max-w-[25rem]'>
      <ZoneMonitorBubbleKey />
    </div>

    { isLoading
      ? <p><LoadingSpinner />Loading...</p>
      : (isError
        ? <p>ERROR</p>
        : <ResultsPanel
          paginator={data.paginator}
          historyResults={data.historyResults}
          days={days}
          setDays={setDays}
          setPage={setPage} />
      )}
  </>
}
