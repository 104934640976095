import { createContext, useState } from 'react'

export const PageTitleContext = createContext({
  title: '',
  subtitle: '',
})

export default function PageTitleProvider({ children }) {
  const [title, setTitle] = useState('')
  const [subtitle, setSubtitle] = useState('')

  const value = {
    title,
    subtitle,
    setTitle: title => setTitle(title),
    setSubtitle: subtitle => setSubtitle(subtitle),
  }

  return (
    <PageTitleContext.Provider value={value}>
      { children }
    </PageTitleContext.Provider>
  )
}
