import React from 'react'
import { Link } from 'react-router-dom'
import useShowNav from '../../Hooks/useShowNav'

export default function SidebarItem({ id, icon, label, link, isActive }) {
  const { toggleShowNav } = useShowNav()

  return (
    <li className={`${isActive ? 'bg-valhalla text-white' : ''}`}>
      <Link to={link} className='flex items-center py-3' onClick={toggleShowNav}>
        <div className={`border-l-2 px-3 border-transparent ${isActive ? 'border-l-aggressive-red' : 'border-transparent'}`}>
          <div className='w-8 h-8'>
            {icon}
          </div>
        </div>
        <h1 className='pl-2 font-montserrat pr-7'>{label}</h1>
      </Link>
    </li>
  )
}
