import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import ReactDatePicker from 'react-datepicker'
import FormGroup from './Form/FormGroup'
import 'react-datepicker/dist/react-datepicker.css'

export default function PeriodPicker({ label, fromDateName, toDateName, setValue, initialFromValue, initialToValue, minDate, maxDate }) {
  // EOL Calander setup
  const [fromDate, setFromDate] = useState() // from todays date
  const [toDate, setToDate] = useState() // to a weeks from now
  const minimumDate = new Date(minDate)
  const maximumDate = new Date(maxDate)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const fromParam = urlParams.get(fromDateName)
    const toParam = urlParams.get(toDateName)

    if (fromParam && toParam) {
      initialiseDates(fromParam, toParam)
    } else if (fromDate == null || toDate == null) {
      initialiseDates(initialFromValue, initialToValue)
    }
  }, [fromDateName, toDateName])

  const initialiseDates = (initialFromDate, initialToDate) => {
    // dayjs(undefined) is todays date
    const from = dayjs(initialFromDate).format('YYYY-MM-DD')
    const to = dayjs(initialToDate).format('YYYY-MM-DD')
    setFromDate(from)
    setToDate(to)
    setValue(fromDateName, from)
    setValue(toDateName, to)
  }

  const changeFromDate = (newFromDate) => {
    const newDate = dayjs(newFromDate).format('YYYY-MM-DD')

    // if the toDate field is not yet set OR
    // if this would make the 'From' date later than the 'To' date
    // move the 'To' to the same date to maintain a positive selection period
    if (toDate == null || dayjs(newFromDate).isAfter(toDate, 'day')) {
      setToDate(newDate)
      setValue(toDateName, newDate)
    }
    setFromDate(newDate)
    setValue(fromDateName, newDate)
  }

  const changeToDate = (newToDate) => {
    const newDate = dayjs(newToDate).format('YYYY-MM-DD')
    // if the toDate field is not yet set OR
    // if this would make the 'To' date before than the 'From' date
    // move the 'From' to the same date to maintain a positive selection period
    if (fromDate == null || dayjs(newToDate).isBefore(fromDate, 'day')) {
      setFromDate(newDate)
      setValue(fromDateName, newDate)
    }
    setToDate(newDate)
    setValue(toDateName, newDate)
  }
  return (
    <FormGroup label={label} labelClassName='w-full max-w-[10rem]'>
      <div className='flex flex-col justify-center py-1 gap-y-1'>
        <p className='font-semibold text-fiord'>From:</p>
        <div className='flex gap-x-4'>
          <ReactDatePicker
            className='p-2 border rounded-md border-mischka'
            onChange={changeFromDate}
            selected={ fromDate ? new Date(fromDate) : null }
            dateFormat='dd MMM yyyy'
            minDate={minimumDate}
            maxDate={maximumDate}
            placeholderText='select date'
          />
        </div>
      </div>
      <div className='flex flex-col justify-center py-1 gap-y-1 px-6'>
        <p className='font-semibold text-fiord'>To:</p>
        <div className='flex gap-x-4'>
          <ReactDatePicker
            className='p-2 border rounded-md border-mischka'
            onChange={changeToDate}
            selected={ toDate ? new Date(toDate) : null }
            dateFormat='dd MMM yyyy'
            minDate={minimumDate}
            maxDate={maximumDate}
            placeholderText='select date'
          />
        </div>
      </div>
    </FormGroup>
  )
}
