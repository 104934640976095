import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import useRepository from '../../Hooks/useRepository'
import UserRepository from '../../Repository/UserRepository'
import Button from '../Form/Button'
import FormGroup from '../Form/FormGroup'
import Input from '../Form/Input'
import PageMainForm from '../PageMainForm'

export default function EditUserForm({ user }) {
  const userRepository = useRepository(UserRepository)
  const queryClient = useQueryClient()
  const navigateTo = useNavigate()
  const { register, handleSubmit } = useForm({
    defaultValues: user
  })

  const updateUserMutation = useMutation(data => (
    userRepository.update(user.id, data)
  ),
  {
    onSuccess: () => {
      queryClient.invalidateQueries('users')
      navigateTo('/admin/users')
    }
  })

  return (
    <PageMainForm
      className='max-w-[500px]'
      onSubmit={handleSubmit(data => updateUserMutation.mutate(data))}
    >
      <FormGroup type='vertical' label='First Name'>
        <Input
          {...register('first_name', { required: true })}
          defaultValue={user.first_name}
        />
      </FormGroup>
      <FormGroup type='vertical' label='Last Name'>
        <Input
          {...register('last_name', { required: true })}
          defaultValue={user.last_name}
        />
      </FormGroup>
      <FormGroup type='vertical' label='Email Address' className='py-2'>
        <p>{user.email_address}</p>
      </FormGroup>
      <FormGroup label='Administrator' labelClassName='w-full max-w-[10rem]' className='py-2'>
        <Input
          type='checkbox'
          {...register('is_admin')}
          defaultChecked={user.is_admin}
        />
      </FormGroup>
      <FormGroup label='Account Locked' labelClassName='w-full max-w-[10rem]' className='py-2'>
        <Input
          type='checkbox'
          {...register('locked')}
          defaultChecked={user.is_locked}
        />
      </FormGroup>

      <hr className='border-mischka border-opacity-60' />

      <FormGroup label='Send Daily Report' labelClassName='w-full max-w-[10rem]' className='py-2'>
        <Input
          type='checkbox'
          {...register('receives_daily_report')}
          defaultChecked={user.receives_daily_report}
        />
      </FormGroup>

      <hr className='border-mischka border-opacity-60' />

      <p className='mt-4'>To change this user's password, enter a new one here:</p>
      <FormGroup type='vertical' label='Password'>
        <Input
          type='password'
          {...register('password')}
        />
      </FormGroup>
      <FormGroup type='vertical' className='items-end'>
        <Button
          type='submit'
          loading={updateUserMutation.isLoading}
        >
          Update User
        </Button>
      </FormGroup>
    </PageMainForm>
  )
}
