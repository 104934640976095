import PanelContainer from './PanelContainer'
import formatDateTime from '../functions/formatDateTimeToDate'
import calcDiffInDays from '../functions/calcDiffInDays'
import createSinceBirthStatistic from '../functions/createSinceBirthStatistic'
import convertToReadableTime from '../functions/convertToReadableTime'

export default function AssetInfoAdmin({ asset }) {
  return (
    <PanelContainer className='flex items-start px-3 lg:basis-1/2 overflow-visible'>
      <div className="flex flex-wrap font-light w-full">
        <div className="w-1/4 px-3">
          <div className="flex flex-col py-3">
            <div className='py-2'>
              <p className='font-bold'>POLL RATE</p>
              <p>
                { asset.telemetry_statistics && asset.telemetry_statistics.number_of_successful_polls
                  // e.g. 2/10 20%
                  ? createSinceBirthStatistic(asset.telemetry_statistics.number_of_successful_polls, asset.created_at)
                  : 'Not yet calculated'}
              </p>
            </div>
            <div className='py-2'>
              <p className='font-bold'>POLL TIMING AVG</p>
              <p>{ asset.telemetry_statistics && asset.telemetry_statistics.average_polling_duration_seconds
                ? convertToReadableTime(asset.telemetry_statistics.average_polling_duration_seconds)
                : 'No available polling history' }</p>
            </div>
          </div>
        </div>
        <div className="w-1/3 justify-between">
          <div className="flex h-full py-3">
            <div className='py-2'>
              <p className='font-bold'>BATTERY USAGE</p>
              <p>Last poll - {asset.battery_percent}%</p>
              <p>{ asset.telemetry_statistics && asset.telemetry_statistics.average_battery_used_per_day
                ? 'Average used per day - ' + Number(asset.telemetry_statistics.average_battery_used_per_day).toFixed(2) + '%'
                : 'Average use not yet calculated'}</p>
              <p className='py-3'>{asset.eol_date ? 'Forecasted life - ' + calcDiffInDays(asset.eol_date) + ' days' : <></>}</p>
            </div>
          </div>
        </div>
        <div className="w-1/3">
          <div className="flex flex-col h-1/2 py-3">
            <div className=' w-full py-2'>
              <p className='font-bold'>LOCATION CHANGE</p>
              <p>
                { asset.telemetry_statistics && asset.telemetry_statistics.number_of_location_changes
                  // e.g. 2/10 20%
                  ? createSinceBirthStatistic(asset.telemetry_statistics.number_of_location_changes, asset.created_at)
                  : 'Not yet calculated'}
              </p>
            </div>
            <div className='basis-1/3 py-2'>
              <p className='font-bold'>EOL DATE (est.)</p>
              <p>{asset.eol_date ? formatDateTime(asset.eol_date, 'D MMMM YYYY', true) : 'Unknown'}</p>
            </div>
          </div>
        </div>
      </div>
    </PanelContainer>
  )
}
