import { createContext, useState } from 'react'

export const BreadcrumbContext = createContext({
  breadcrumbs: []
})

export default function BreadcrumbProvider({ children }) {
  const [breadcrumbs, setBreadcrumbs] = useState([])

  const value = {
    breadcrumbs,
    setBreadcrumbs: breadcrumbs => setBreadcrumbs(breadcrumbs)
  }

  return (
    <BreadcrumbContext.Provider value={value}>
      { children }
    </BreadcrumbContext.Provider>
  )
}
