import { PencilSquareIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router'
import PanelContainer from '../PanelContainer'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableHeader from '../Table/TableHeader'
import TableHeading from '../Table/TableHeading'
import TableRow from '../Table/TableRow'
import createZoneTypeBubble from '../../functions/createZoneTypeBubble'
import ButtonSquare from '../Form/SquareButton'
import MapLink from '../MapLink'

export default function SiteTable({ data }) {
  const navigateTo = useNavigate()

  if (data.length === 0) {
    return (
      <div className='flex items-center justify-between'>
        <p>No results</p>
      </div>
    )
  }

  return (
    <>
      <div className='flex items-center justify-between'>
        <p>Showing records 1 to {data.length}</p>
      </div>

      <PanelContainer>
        <Table>
          <TableHeader>
            <TableHeading>Organisation</TableHeading>
            <TableHeading>Site Name</TableHeading>
            <TableHeading>
              <div className='text-center'>Zone</div>
            </TableHeading>
            <TableHeading>Site address</TableHeading>
            <TableHeading>Size</TableHeading>
            <TableHeading>Lat</TableHeading>
            <TableHeading>Long</TableHeading>
            <TableHeading>Assets</TableHeading>
            <TableHeading />
          </TableHeader>
          <tbody>
            {data.map((site, key) => {
              return (
                <TableRow key={`sites-row-${key}`}>
                  <TableCell>{site.organisation_name}</TableCell>
                  <TableCell>{site.name}</TableCell>
                  <TableCell>
                    <div className='flex justify-center'>
                      {createZoneTypeBubble(true, site.is_third_party)}
                    </div>
                  </TableCell>
                  <TableCell>
                    <MapLink name={site.name} latitude={site.latitude} longitude={site.longitude} text={site.location} />
                  </TableCell>
                  <TableCell className='whitespace-nowrap'>{site.zone_size / 1000} km</TableCell>
                  <TableCell>{site.latitude}</TableCell>
                  <TableCell>{site.longitude}</TableCell>
                  <TableCell>{site.asset_count}</TableCell>
                  <TableCell className='justify-end mr-6'>
                    <ButtonSquare onClick={() => navigateTo(`/organisations/sites/${site.id}/edit`)}>
                      <PencilSquareIcon className='w-6 h-6' />
                    </ButtonSquare>
                  </TableCell>
                </TableRow>
              )
            })}
          </tbody>
        </Table>
      </PanelContainer>
    </>
  )
}
