import { DocumentDuplicateIcon } from '@heroicons/react/24/outline'
import DashboardCard from '../../Card/DashboardCard'

export default function PolledWithin30DaysCard({ value, percentage }) {
  return (
    <DashboardCard
      icon={<DocumentDuplicateIcon />}
      value={value}
      description='Polled within 30 days'
      backgroundColour='bg-warning'
      valueColour='text-white'
      textColour='text-white'
      buttonColour='bg-white'
      percentage={percentage}
      url='/reports/search?pingLast30Days=true'
    />
  )
}
