import { ArrowUpRightIcon } from '@heroicons/react/24/outline'

export default function DashboardCardButton({
  buttonColour = '',
  url
}) {
  return (
    <div className='self-end pt-2'>
      <span className={`flex items-center justify-center w-8 h-8 bg-opacity-40 rounded-full bg-white ${buttonColour}`}>
        <ArrowUpRightIcon className='w-4 h-4 text-white' />
      </span>
    </div>
  )
}
