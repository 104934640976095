import PanelContainer from '../PanelContainer'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import Row from './Row'

export default function Forecasting({ forecastingAnalysis }) {
  return (
    <PanelContainer className='w-full max-w-[970px] py-4 px-4'>
      <div className="flex text-right font-light">
        <div className="flex flex-col mr-6 text-left w-2/6">
          <div className="flex items-center">
            <div className="font-bold text-xl mr-4">Forecasting</div>
            <MagnifyingGlassIcon className="w-6 h-6" />
          </div>
          <Row>Predicted battery life - days</Row>
          <Row>Predicted battery life - months</Row>
          <Row>Predicted battery life - years</Row>
        </div>

        <div className="flex flex-col w-28">
          <div className="font-bold text-xl">AVG</div>
          <Row>{forecastingAnalysis.predictedBatteryLifeDays.avg}</Row>
          <Row>{forecastingAnalysis.predictedBatteryLifeMonths.avg}</Row>
          <Row>{forecastingAnalysis.predictedBatteryLifeYears.avg}</Row>
        </div>

        <div className="flex flex-col w-28">
          <div className="font-bold text-xl">MAX</div>
          <Row>{forecastingAnalysis.predictedBatteryLifeDays.max}</Row>
          <Row>{forecastingAnalysis.predictedBatteryLifeMonths.max}</Row>
          <Row>{forecastingAnalysis.predictedBatteryLifeYears.max}</Row>
        </div>

        <div className="flex flex-col w-28">
          <div className="font-bold text-xl">MIN</div>
          <Row>{forecastingAnalysis.predictedBatteryLifeDays.min}</Row>
          <Row>{forecastingAnalysis.predictedBatteryLifeMonths.min}</Row>
          <Row>{forecastingAnalysis.predictedBatteryLifeYears.min}</Row>
        </div>
      </div>
    </PanelContainer>
  )
}
