export default function LoginPanel({ children }) {
  return (
    <div className='w-screen h-screen bg-bg-entry bg-cover'>
      <div className='backdrop-blur w-screen h-screen flex items-center justify-center'>
        <div className='flex flex-col items-center justify-center gap-4 p-6 text-center bg-white shadow-2xl rounded-2xl w-72'>
          {children}
        </div>
      </div>
    </div>
  )
}
