import PanelContainer from '../PanelContainer'
import In3pBubble from '../Table/TableZoneBubbles/In3pBubble'
import InZoneBubble from '../Table/TableZoneBubbles/InZoneBubble'
import NotInZoneBubble from '../Table/TableZoneBubbles/NotInZoneBubble'

export default function ZoneMonitorBubbleKey() {
  return (
    <PanelContainer className='flex-col p-4 space-y-1 text-sm font-light font-montserrat'>
      <div className='flex items-center gap-x-2'>
        <InZoneBubble />
        <p className='flex-grow'>Pallet is in one of your sites/zones</p>
      </div>
      <div className='flex items-center gap-x-2'>
        <In3pBubble />
        <p className='flex-grow'>Pallet is in a 3rd party site/zone</p>
      </div>
      <div className='flex items-center gap-x-2'>
        <NotInZoneBubble />
        <p className='flex-grow'>Pallet is out of zone</p>
      </div>
    </PanelContainer>
  )
}
