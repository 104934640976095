
/**
 * Used in several places where we create and edit Sites
 */
const SiteDistances = {
  options: [
    { text: '500m', value: 500 },
    { text: '1km', value: 1000 },
    { text: '1.5km', value: 1500 },
    { text: '2km', value: 2000 },
    { text: '2.5km', value: 2500 },
    { text: '3km', value: 3000 },
    { text: '3.5km', value: 3500 },
    { text: '4km', value: 4000 },
    { text: '4.5km', value: 4500 },
    { text: '5km', value: 5000 },
  ],
  default: 1000
}

export default SiteDistances
