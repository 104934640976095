import { ChartPieIcon } from '@heroicons/react/24/outline'
import { useMatch } from 'react-router'
import SidebarItem from '../SidebarItem'

export default function DashboardItem() {
  const isActive = useMatch('/')
  return (
    <SidebarItem
      icon={<ChartPieIcon />}
      label="Dashboard"
      link="/"
      isActive={isActive}
    />
  )
}
