import dayjs from 'dayjs'
import PanelContainer from '../PanelContainer'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableHeader from '../Table/TableHeader'
import TableHeading from '../Table/TableHeading'
import TableRow from '../Table/TableRow'
import createPollDateBubble from '../../functions/createPollDateBubble'
import createDateDiffText from '../../functions/createDateDiffText'
import createZoneTypeBubble from '../../functions/createZoneTypeBubble'
import MapLink from '../MapLink'
import formatAssetName from '../../functions/formatAssetName'
import { Link } from 'react-router-dom'
import calcDiffInDays from '../../functions/calcDiffInDays'
import { useState } from 'react'
import SortableTableHeading from '../Table/SortableTableHeading'
import formatLastPolledTime from '../../functions/formatLastPolledTime'
import { HeartIcon as HeartIconOutline } from '@heroicons/react/24/outline'
import FavouriteAssetButton from '../Table/Controls/FavouriteAssetButton'
import { useQueryClient } from '@tanstack/react-query'
import { useDebouncedCallback } from 'use-debounce'

export default function ZoneMonitorTable({ fixDate, data }) {
  const queryClient = useQueryClient()
  const [sortBy, setSortBy] = useState('device_name')
  const [sortDir, setSortDir] = useState('asc')

  if (sortBy && sortDir) {
    switch (sortBy) {
    case 'device_name':
    case 'last_update_time':
    case 'address':
    case 'fix_date_address':
    case 'site_name':
      data.sort((a, b) => sortDir === 'asc'
        ? a[sortBy].localeCompare(b[sortBy])
        : b[sortBy].localeCompare(a[sortBy])
      )
      break
    default:
      data.sort((a, b) => sortDir === 'asc'
        ? a[sortBy] - b[sortBy]
        : b[sortBy] - a[sortBy]
      )
    }
  }

  const updateSortOptions = (column) => {
    // If we're not currently sorting by this column, sort ascending
    if (sortBy !== column) {
      setSortBy(column)
      setSortDir('asc')
      return
    }

    // If we're already sorting on this column, flip the direction
    setSortDir(sortDir === 'asc' ? 'desc' : 'asc')
  }

  const invalidateQueriesCallback = useDebouncedCallback(() => queryClient.invalidateQueries(['out-of-zone-monitor']), 1000)

  return (
    <PanelContainer className='my-4'>
      <Table>
        <TableHeader>
          <TableHeading>Status</TableHeading>
          <SortableTableHeading sortName='device_name' sortingBy={sortBy} sortingDirection={sortDir} onSort={updateSortOptions}>
            Pallet
          </SortableTableHeading>
          <SortableTableHeading sortName='last_update_time' sortingBy={sortBy} sortingDirection={sortDir} onSort={updateSortOptions}>
            Last Ping
          </SortableTableHeading>
          <TableHeading />
          <SortableTableHeading sortName='fix_date_address' sortingBy={sortBy} sortingDirection={sortDir} onSort={updateSortOptions}>
            Location at {dayjs(fixDate).format('D MMM YYYY')}
          </SortableTableHeading>
          <SortableTableHeading sortName='address' sortingBy={sortBy} sortingDirection={sortDir} onSort={updateSortOptions}>
            Location at last ping
          </SortableTableHeading>
          <TableHeading />
          <SortableTableHeading sortName={'site_name'} sortingBy={sortBy} sortingDirection={sortDir} onSort={updateSortOptions}>
            Last Official Zone
          </SortableTableHeading>
          <TableHeading>
            <HeartIconOutline className='w-7 h-7 ml-auto' />
          </TableHeading>
        </TableHeader>

        <tbody>
          { data.map((asset, key) => {
            const diff = calcDiffInDays(asset.last_update_time)
            const assetName = formatAssetName(asset.device_name)

            return (
              <TableRow key={`table-row-${key}`}>
                <TableCell>{createPollDateBubble(diff)}</TableCell>
                <TableCell><Link to={`/reports/asset-history/${asset.device_id}`} className='underline'>{assetName}</Link></TableCell>

                <TableCell className='flex-col items-baseline whitespace-nowrap'>
                  {formatLastPolledTime(asset.last_update_time)}<br />{createDateDiffText(diff)}
                </TableCell>

                <TableCell>{createZoneTypeBubble(asset.fix_date_in_zone, asset.fix_date_third_party_zone)}</TableCell>
                <TableCell className='whitespace-pre-line max-w-[260px]'><MapLink longitude={asset.fix_date_longitude} latitude={asset.fix_date_latitude} name={assetName} text={asset.fix_date_address} /></TableCell>
                <TableCell className='whitespace-pre-line max-w-[260px]'><MapLink longitude={asset.longitude} latitude={asset.latitude} name={assetName} text={asset.address} /></TableCell>
                <TableCell>{createZoneTypeBubble(asset.is_in_zone, asset.is_third_party_zone)}</TableCell>

                <TableCell className='whitespace-pre-line'>
                  {asset.site_name} <br />
                  {dayjs(asset.last_update_time).format('D MMM YYYY')}
                </TableCell>

                <TableCell className='text-right'>
                  <FavouriteAssetButton asset={asset} onMutationSuccess={() => invalidateQueriesCallback()} />
                </TableCell>

              </TableRow>
            )
          })}
        </tbody>
      </Table>
    </PanelContainer>
  )
}
