import { Cog8ToothIcon } from '@heroicons/react/24/outline'
import { useMatch } from 'react-router'
import SidebarItem from '../SidebarItem'

export default function AdminItem() {
  const isActive = useMatch('/admin')
  return (
    <SidebarItem
      icon={<Cog8ToothIcon />}
      label="Admin"
      link="/admin"
      isActive={isActive}
    />
  )
}
