import { useMutation } from '@tanstack/react-query'
import useRepository from '../../../Hooks/useRepository'
import { useMemo, useState } from 'react'
import AssetRepository from '../../../Repository/AssetRepository'
import { HeartIcon as HeartIconOutline } from '@heroicons/react/24/outline'
import { HeartIcon as HeartIconSolid } from '@heroicons/react/24/solid'
import ReactTooltip from '../../ReactTooltip'

export default function FavouriteAssetButton({ asset, onMutationSuccess }) {
  const assetRepository = useRepository(AssetRepository)
  const [isFavoured, setIsFavoured] = useState(!!(asset.isFavourited || asset.is_favourited))

  useMemo(() => {
    setIsFavoured(asset.isFavourited || asset.is_favourited)
  }, [asset])

  const { mutate: removeFavourite } = useMutation({
    mutationFn: assetId => assetRepository.removeFavourite(assetId),
    onMutate: () => setIsFavoured(false),
    onSuccess: () => onMutationSuccess()
  })

  const { mutate: addFavourite } = useMutation({
    mutationFn: assetId => assetRepository.addFavourite(assetId),
    onMutate: () => setIsFavoured(true),
    onSuccess: () => onMutationSuccess()
  })

  return (
    <div className="w-7 h-7 ml-auto relative">
      {
        isFavoured
          ? (
            <a
              data-tooltip-id="remove-from-watchlist"
              data-tooltip-content="Remove from my watch list"
              data-tooltip-place="left"
              data-tooltip-hidden={!isFavoured}
              data-tooltip-delay-show={250}
            >
              <ReactTooltip id="remove-from-watchlist"/>
              <HeartIconSolid
                className='cursor-pointer text-aggressive-red'
                onClick={() => removeFavourite(asset.device_id)}
              />
            </a>
          )
          : (
            <a
              data-tooltip-id="add-to-watchlist"
              data-tooltip-content="Add to my watch list"
              data-tooltip-place="left"
              data-tooltip-hidden={isFavoured}
              data-tooltip-delay-show={250}
            >
              <ReactTooltip id="add-to-watchlist" />
              <HeartIconOutline
                className='cursor-pointer'
                onClick={() => addFavourite(asset.device_id)}
              />
            </a>
          )
      }
    </div>
  )
}
