import In3pBubble from '../components/Table/TableZoneBubbles/In3pBubble'
import InZoneBubble from '../components/Table/TableZoneBubbles/InZoneBubble'
import NotInZoneBubble from '../components/Table/TableZoneBubbles/NotInZoneBubble'

export default function createZoneTypeBubble(isInZone, isThirdPartyZone) {
  if (!isInZone) {
    return <NotInZoneBubble />
  }
  if (isThirdPartyZone) {
    return <In3pBubble />
  }
  return <InZoneBubble />
}
