export default function DashboardCardHeader({
  icon = null,
  iconColour = 'text-black',
  value = null,
  valueColour = iconColour,
}) {
  return (
    <>
      {icon &&
        <div className={`w-8 mb-3 h-8 ${iconColour}`}>
          {icon}
        </div>
      }
      <h1 className={`text-2xl font-semibold ${valueColour}`}>
        {value}
      </h1>
    </>
  )
}
