import { useContext, useEffect } from 'react'
import { PageTitleContext } from '../Providers/PageTitleProvider'

export default function usePageTitle(title = '', subtitle = '', deps = []) {
  const { title: storedTitle, subtitle: storedSubtitle, setTitle, setSubtitle } = useContext(PageTitleContext)

  useEffect(() => {
    setTitle(title)
    setSubtitle(subtitle)

    return () => {
      setTitle('')
      setSubtitle('')
    }
  }, deps)

  return {
    title: storedTitle,
    subtitle: storedSubtitle
  }
}
