import { Link } from 'react-router-dom'

export default function LinkCard({ children, url, backgroundColour, className }) {
  return (
    <Link
      to={url}
      className={`flex flex-col p-3 my-3 border-mischka border border-opacity-10 rounded-2xl shadow-md w-full max-w-[136px] ${backgroundColour} ${className}`}
    >
      {children}
    </Link>
  )
}
