import createBackgroundClassFromText from '../../../functions/createBackgroundClassFromText'

export default function PollBubble({ value, hint = '', type = 'success' }) {
  const bgClass = createBackgroundClassFromText(type)

  return (
    <div
      className={`inline-flex items-center justify-center text-xs font-medium text-white rounded-full w-7 h-7 ${bgClass}`}
      title={hint}
    >
      {value}
    </div>
  )
}
