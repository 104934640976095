import { MapPinIcon } from '@heroicons/react/24/outline'
import DashboardCard from '../DashboardCard'

export default function OutOfZoneCard({ value, showIcon, percentage }) {
  return (
    <DashboardCard
      icon={showIcon ? <MapPinIcon /> : null}
      value={value}
      description='Pallets out of zone'
      backgroundColour='bg-white'
      valueColour='text-danger'
      textColour='text-black'
      buttonColour='bg-shady-lady'
      percentage={percentage}
      url='/reports/search?outOfZone=true'
    />
  )
}
