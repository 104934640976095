import { CheckIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { useNavigate } from 'react-router'
import PanelContainer from '../PanelContainer'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableHeader from '../Table/TableHeader'
import TableHeading from '../Table/TableHeading'
import TableRow from '../Table/TableRow'
import ButtonSquare from '../Form/SquareButton'

export default function UserTable({ users }) {
  const navigateTo = useNavigate()

  if (users.length === 0) {
    return (
      <p>No results</p>
    )
  }

  return (
    <div className='flex flex-col gap-6'>
      <p>Showing records 1 to {users.length}</p>

      <PanelContainer>
        <Table>
          <TableHeader>
            <TableHeading>Customer</TableHeading>
            <TableHeading>First name</TableHeading>
            <TableHeading>Last name</TableHeading>
            <TableHeading>Email Address</TableHeading>
            <TableHeading className='text-center'>Daily report</TableHeading>
            <TableHeading className='text-center'>Administrator</TableHeading>
            <TableHeading className='text-center'>Locked</TableHeading>
            <TableHeading/>
          </TableHeader>
          <tbody>
            {users.map((user, key) => (
              <TableRow key={`organisation-row-${key}`}>
                <TableCell>{user.customer_name}</TableCell>
                <TableCell>{user.first_name}</TableCell>
                <TableCell>{user.last_name}</TableCell>
                <TableCell>{user.email_address}</TableCell>
                <TableCell>{user.receives_daily_report ? <CheckIcon className='w-6 h-6 mx-auto' /> : ''}</TableCell>
                <TableCell>{user.is_admin ? <CheckIcon className='w-6 h-6 mx-auto' /> : ''}</TableCell>
                <TableCell>{user.is_locked ? <CheckIcon className='w-6 h-6 mx-auto' /> : ''}</TableCell>
                <TableCell className='text-right'>
                  <ButtonSquare onClick={() => navigateTo(`/admin/users/${user.id}/edit`)} className='px-[12px]'>
                    <PencilSquareIcon className='w-6 h-6' />
                  </ButtonSquare>
                </TableCell>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </PanelContainer>
    </div>
  )
}
