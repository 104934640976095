import AssetHistoryCard from '../../Card/AssetHistoryCard'

export default function PolledOver30Days({ value }) {
  return (
    <AssetHistoryCard
      className='pb-4'
      backgroundColour='bg-danger'
      value={value}
      valueColour='text-white'
      description='Polled more than 30 days ago'
      textColour='text-white'
    />
  )
}
