import AssetHistoryCard from '../../Card/AssetHistoryCard'

export default function Offline({ value }) {
  return (
    <AssetHistoryCard
      className='pb-4'
      backgroundColour='bg-secondary'
      value={value}
      valueColour='text-white'
      description='Offline'
      textColour='text-white'
    />
  )
}
