import { MapPinIcon } from '@heroicons/react/24/outline'
import CardRow from './Card/CardRow'
import PanelContainer from './PanelContainer'
import PolledWithin7Days from './AssetsByOrganisation/Cards/SitePolledWithin7Days'
import PolledWithin30Days from './AssetsByOrganisation/Cards/SitePolledWithin30Days'
import PolledAfter30Days from './AssetsByOrganisation/Cards/SitePolledAfter30Days'
import AllAssets from './AssetsByOrganisation/Cards/SiteAllAssets'
import InZone from './AssetsByOrganisation/Cards/SiteInZone'
import OutOfZone from './AssetsByOrganisation/Cards/SiteOutOfZone'

export default function SiteSummaryPanel({ data }) {
  return (
    <PanelContainer className='p-3 w-fit'>
      <div className='flex gap-1'>
        <MapPinIcon className='w-12 h-12' />
        <h1 className='mt-2 font-bold'>{data.site_name}</h1>
      </div>

      <CardRow className='pl-10 pr-4'>
        <PolledWithin7Days value={data.polled_last_7_days} siteId={data.site_id} />
        <PolledWithin30Days value={data.polled_last_30_days} siteId={data.site_id} />
        <PolledAfter30Days value={data.polled_over_30_days} siteId={data.site_id} />
        <AllAssets value={data.all_assets} siteId={data.site_id} />
        <InZone value={data.in_zone} siteId={data.site_id} />
        <OutOfZone value={data.out_of_zone} siteId={data.site_id} />
      </CardRow>
    </PanelContainer>
  )
}
