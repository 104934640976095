import { useMutation, useQueries, useQueryClient } from '@tanstack/react-query'
import { useRef, useState } from 'react'
import { useParams } from 'react-router'
import AssetHistoryTable from '../components/AssetHistoryTable'
import AssetInfo from '../components/AssetInfo'
import AssetInfoAdmin from '../components/AssetInfoAdmin'
import Dropdown from '../components/Dropdown'
import LoadingSpinner from '../components/LoadingSpinner'
import Notes from '../components/Notes'
import PanelContainer from '../components/PanelContainer'
import formatAssetName from '../functions/formatAssetName'
import useRepository from '../Hooks/useRepository'
import AssetRepository from '../Repository/AssetRepository'
import DashboardRepository from '../Repository/DashboardRepository'
import useBreadcrumbs from '../Hooks/useBreadcrumbs'
import usePageTitle from '../Hooks/usePageTitle'
import useAuth from '../Hooks/useAuth'

export default function AssetHistoryPage() {
  const queryClient = useQueryClient()
  const params = useParams()
  const assetRepository = useRepository(AssetRepository)
  const dashboardRepository = useRepository(DashboardRepository)
  const [recentHistoryDays, setRecentHistoryDays] = useState(7)
  const noteRef = useRef()
  const { isAdminUser } = useAuth()

  const assetId = Number(params.asset_id)

  const [
    { data: asset, isLoading, isError },
    { data: assetHistory, isLoading: isHistoryLoading, isError: isHistoryError }
  ] = useQueries({
    queries: [{
      queryKey: ['asset', assetId],
      queryFn: () => assetRepository.get(assetId),
      onSuccess: (response) => {
        // Doing this in the success callback because it doesn't affect state
        dashboardRepository.setLastViewedAssetName(
          response.device_id,
          formatAssetName(response.device_name)
        )
      }
    }, {
      queryKey: ['asset-history', assetId, recentHistoryDays],
      queryFn: () => assetRepository.history(assetId, recentHistoryDays)
    }]
  })

  const createNoteMutation = useMutation({
    mutationFn: () => assetRepository.createNote(assetId, noteRef.current.value),
    onSuccess: () => {
      queryClient.invalidateQueries(['asset', assetId])
      noteRef.current.value = ''
    }
  })

  useBreadcrumbs([
    { label: 'Report menu', link: '/reports' },
    { label: 'Asset history', link: '/reports/asset-history' },
  ])

  // Tricky to handle this nicely because we need the hook calls in place in
  // advance before we actually have the asset object ready
  usePageTitle(
    `Pallet location history for ${asset?.name ?? '...'}`,
    'All information to include historical polling data for the chosen pallet',
    [asset, asset?.name]
  )

  if (!isLoading && !isError) {
    asset.name = formatAssetName(asset.device_name)
  }

  return <>
    { isLoading
      ? <p className='flex'><LoadingSpinner /> Loading...</p>
      : (isError
        ? <p>ERROR</p>
        : <>
          <div className='flex-col lg:flex-row flex gap-6 my-5'>
            <AssetInfo asset={asset} />
            <Notes
              notes={asset.notes}
              onSubmit={createNoteMutation.mutate}
              isLoading={createNoteMutation.isLoading}
              isError={createNoteMutation.isError}
              ref={noteRef}
            />
          </div>
          { isAdminUser
            ? <div className='flex-col lg:flex-row flex gap-6 -mt-8'>
              <AssetInfoAdmin asset={asset} />
              <div className="lg:basis-1/2"></div>
            </div>
            : <></>}

          <PanelContainer className='w-fit'>
            <Dropdown className='w-fit'
              options={[
                { text: 'Last 7 days history', value: 7 },
                { text: 'Last 30 days history', value: 30 },
                { text: 'Last 60 days history', value: 60 },
                { text: 'Last 90 days history', value: 90 },
                { text: 'All history', value: '-1' }
              ]}
              value={recentHistoryDays}
              onChange={e => setRecentHistoryDays(e.currentTarget.value)}
            />
          </PanelContainer>

          { isHistoryLoading
            ? <p className='py-8'><LoadingSpinner /> Loading Asset History...</p>
            : (isHistoryError
              ? <p>ERROR</p>
              : <AssetHistoryTable asset={asset} history={assetHistory} />
            )
          }
        </>
      )
    }
  </>
}
