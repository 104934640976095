import LinkCard from './LinkCard'
import DashboardCardBody from './cardComponents/DashboardCardBody'
import DashboardCardButton from './cardComponents/DashboardCardButton'
import DashboardCardHeader from './cardComponents/DashboardCardHeader'

export default function DashboardCard({
  icon = null,
  backgroundColour = 'bg-white',
  textColour = 'text-black',
  value = null,
  valueColour = textColour,
  description = null,
  percentage = null,
  buttonColour = 'bg-white',
  url,
}) {
  return (
    <LinkCard backgroundColour={backgroundColour} className='hover:shadow-lg' url={url}>
      <DashboardCardHeader value={value} valueColour={valueColour} icon={icon} iconColour={textColour} />
      <DashboardCardBody description={description} percentage={percentage} textColour={textColour} />
      {url == null ? <></> : <DashboardCardButton buttonColour={buttonColour} url={url} /> }
    </LinkCard>
  )
}
