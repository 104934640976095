import { useQuery } from '@tanstack/react-query'
import calcPercentage from '../../functions/calcPercentage'
import useRepository from '../../Hooks/useRepository'
import ReportRepository from '../../Repository/ReportRepository'
import CardRow from '../Card/CardRow'
import AllAssets from './Cards/AllAssets'
import PolledOver30Days from './Cards/PolledOver30Days'
import PolledWithin30Days from './Cards/PolledWithin30Days'
import PolledWithin7Days from './Cards/PolledWithin7Days'
import LoadingSpinner from '../LoadingSpinner'
import SiteSummaryPanel from '../SiteSummaryPanel'

export default function AssetSummaryList({ organisationId }) {
  const reportRepository = useRepository(ReportRepository)

  const { data, isLoading, isError } = useQuery({
    queryKey: ['assets-by-organisation', organisationId],
    queryFn: () => reportRepository.loadAssetsByOrganisation(organisationId)
  })

  const { allAssets, polledWithin7Days, polledWithin30Days, polledAfter30Days } =
    (data ?? []).reduce((acc, record) => ({
      allAssets: acc.allAssets + record.all_assets,
      polledWithin7Days: acc.polledWithin7Days + record.polled_last_7_days,
      polledWithin30Days: acc.polledWithin30Days + record.polled_last_30_days,
      polledAfter30Days: acc.polledAfter30Days + record.polled_over_30_days
    }), { allAssets: 0, polledWithin7Days: 0, polledWithin30Days: 0, polledAfter30Days: 0 })

  return (
    isLoading
      ? <p><LoadingSpinner /> Loading data...</p>
      : (isError
        ? <p>ERROR</p>
        : <>
          { data.length === 0
            ? <p>No Assets found linked to this Organisation</p>
            : <CardRow className='pb-3 pl-10'>
              <AllAssets value={allAssets} />
              <PolledWithin7Days value={polledWithin7Days} percentage={calcPercentage(allAssets, polledWithin7Days)} />
              <PolledWithin30Days value={polledWithin30Days} percentage={calcPercentage(allAssets, polledWithin30Days)} />
              <PolledOver30Days value={polledAfter30Days} percentage={calcPercentage(allAssets, polledAfter30Days)} />
            </CardRow>
          }

          <div className='flex flex-col gap-5 py-2'>
            {(data ?? []).map((data, key) => (
              <SiteSummaryPanel data={data} key={`site-list-${key}`} />
            ))}
          </div>
        </>
      )
  )
}
