import { useState } from 'react'
import { Link } from 'react-router-dom'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'

export default function BatteryFailYear({
  year,
  countTotal,
  months,
}) {
  const [activeYear, setActiveYear] = useState(null)

  const handleYearDropdownToggle = (year) => {
    setActiveYear(isExpanded ? null : year)
  }

  const handleMonthClick = (year, month) => {
    const startDate = new Date(year, month - 1, 2)
    const endDate = new Date(year, month, 1)
    const eolStartDate = startDate.toISOString().split('T')[0] // discard timestamp
    const eolEndDate = endDate.toISOString().split('T')[0]
    const url = `/reports/search?eolStartDate=${encodeURIComponent(
      eolStartDate
    )}&eolEndDate=${encodeURIComponent(eolEndDate)}`
    // Navigate to the new page with the URL and submit the parameters
    window.location.href = url
  }

  const handleYearClick = (year) => {
    const startDate = new Date(year, 0, 1) // Start of the year
    const endDate = new Date(year, 11, 31) // End of the year
    const eolStartDate = startDate.toISOString().split('T')[0]
    const eolEndDate = endDate.toISOString().split('T')[0]
    const url = `/reports/search?eolStartDate=${encodeURIComponent(
      eolStartDate
    )}&eolEndDate=${encodeURIComponent(eolEndDate)}`
    // Navigate to the new page with the URL and submit the parameters
    window.location.href = url
  }

  const isExpanded = activeYear === year

  return (
    <div key={year} className="flex flex-col">
      <div
        className={`mr-4 font-bold pt-2 cursor-pointer ${
          isExpanded ? 'text-blue-500' : ''
        }`}
        onClick={() => handleYearDropdownToggle(year)}
      >
        <div className="flex items-center">
          <span>{`${year} (${countTotal})`}</span>
          {isExpanded ? <ChevronUpIcon className="h-5 w-5" /> : <ChevronDownIcon className="h-5 w-5" />}
        </div>
      </div>
      {isExpanded && (
        <div className="flex flex-wrap">
          <Link
            className="mr-2 mb-2 p-2 bg-gray-200 rounded-lg cursor-pointer"
            onClick={() => handleYearClick(year)}
          >
            View Year
          </Link>
          {months.map((monthObj, index) => {
            const [month, count] = Object.entries(monthObj)[0]
            const monthName = new Date(`${year}-${month}`).toLocaleString('en-US', {
              month: 'short',
            })
            return (
              <Link
                key={index}
                className="mr-2 mb-2 p-2 bg-gray-200 rounded-lg cursor-pointer"
                onClick={() => handleMonthClick(year, month)}
              >
                {`${monthName} (${count})`}
              </Link>
            )
          })}
        </div>
      )}
    </div>
  )
}
