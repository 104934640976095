export default function OrganisationRepository(client) {
  const list = () => (
    client.get('api/organisations')
      .then(response => response.data.data)
  )

  const create = (data) => (
    client.post('api/organisations', data)
  )

  const get = (id) => (
    client.get(`api/organisations/${id}`)
      .then(response => response.data.data)
  )

  const update = (organisationId, data) => {
    return client.put(`api/organisations/${organisationId}`, data)
  }

  return {
    list,
    create,
    get,
    update
  }
}
