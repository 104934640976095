import PanelContainer from '../PanelContainer'
import ButtonSquare from '../Form/SquareButton'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableHeader from '../Table/TableHeader'
import TableHeading from '../Table/TableHeading'
import TableBody from '../Table/TableBody'
import TableRow from '../Table/TableRow'
import { PencilSquareIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router'

export default function CustomersTable({ customers }) {
  const navigateTo = useNavigate()

  if (customers.length === 0) {
    return (
      <p>No reuslts</p>
    )
  }

  return (
    <PanelContainer>
      <Table>
        <TableHeader>
          <TableHeading>Customer Name</TableHeading>
          <TableHeading className='text-center'>Flespi Group ID</TableHeading>
          <TableHeading className='text-center'>Users</TableHeading>
          <TableHeading className='text-center'>Organisations</TableHeading>
          <TableHeading className='text-center'>Sites</TableHeading>
          <TableHeading className='text-center'>Assets</TableHeading>
          <TableHeading className='text-center'>Asset Movement Tolerance</TableHeading>
          <TableHeading />
        </TableHeader>
        <TableBody>
          {customers.map((customer, key) => (
            <TableRow key={`customer-${key}`}>
              <TableCell>{customer.name}</TableCell>
              <TableCell className='w-[6rem] text-center'>{customer.flespi_group_id}</TableCell>
              <TableCell className='w-[6rem] text-center'>{customer.user_count}</TableCell>
              <TableCell className='w-[6rem] text-center'>{customer.org_count}</TableCell>
              <TableCell className='w-[6rem] text-center'>{customer.site_count}</TableCell>
              <TableCell className='w-[6rem] text-center'>{customer.asset_count}</TableCell>
              <TableCell className='w-[6rem] text-center'>{customer.asset_movement_tolerance}m</TableCell>
              <TableCell className='w-[5rem]'>
                <ButtonSquare onClick={() => navigateTo(`/admin/customers/${customer.id}/edit`)}>
                  <PencilSquareIcon className='w-6 h-6' />
                </ButtonSquare>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </PanelContainer>
  )
}
