import React from 'react'
import { Tooltip } from 'react-tooltip'

const ReactTooltip = ({ id, ...props }) => {
  return (
    <Tooltip id={id} {...props} />
  )
}

export default ReactTooltip
