import PanelContainer from '../PanelContainer'
import Table from '../Table/Table'
import ResultsTableBody from './ResultsTableBody'
import ResultsTableHeader from './ResultsTableHeader'

export default function ResultsTable({ dateRange, results }) {
  return (
    <PanelContainer className='w-fit max-w-full'>
      <Table>
        <ResultsTableHeader dateRange={dateRange} />
        <ResultsTableBody dateRange={dateRange} results={results} />
      </Table>
    </PanelContainer>
  )
}
