import { MapPinIcon } from '@heroicons/react/24/outline'
import ReportsListItem from '../ReportsListItem'

export default function UsageFleetReportLink() {
  return (
    <ReportsListItem
      icon={<MapPinIcon className='w-11 h-11' />}
      heading='Usage fleet report'
      body='Statistical overview of fleet (filterable by date)'
      url='/reports/usage-fleet-report'
    />
  )
}
