import dayjs from 'dayjs'

export default function formatLastPolledTime(lastUpdateTime, format = 'DD MMM YYYY', useOrdinalSuffix = false) {
  const date = dayjs(lastUpdateTime).format(format)
  if (useOrdinalSuffix) {
    const splitDate = date.split(' ')
    splitDate[0] += nth(splitDate[0])
    return splitDate.join(' ')
  } else {
    return date
  }
}

const nth = function(d) {
  if (d > 3 && d < 21) return 'th'
  switch (d % 10) {
  case 1: return 'st'
  case 2: return 'nd'
  case 3: return 'rd'
  default: return 'th'
  }
}
