import AssetsByOrganisationCard from './AssetsByOrganisationCard'

export default function PolledAfter30Days({ siteId, value }) {
  return (
    <AssetsByOrganisationCard
      value={value}
      description='Polled more than 30 days ago'
      colour='text-danger'
      url={`/reports/search?pingOver30Days=true&site=${siteId}`}
    />
  )
}
