import Form from './Form/Form'

export default function PageMainForm({ onSubmit, children, className, ...props }) {
  return (
    <div className={`my-4 border border-mischka border-opacity-10 shadow-md rounded-2xl bg-white-smoke ${className}`}>
      <Form onSubmit={onSubmit} {...props}>
        {children}
      </Form>
    </div>
  )
}
