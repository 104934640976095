import { useMutation, useQueryClient } from '@tanstack/react-query'
import React from 'react'
import { useForm } from 'react-hook-form'
import useRepository from '../../Hooks/useRepository'
import UserRepository from '../../Repository/UserRepository'
import Button from '../Form/Button'
import Form from '../Form/Form'
import FormGroup from '../Form/FormGroup'
import Input from '../Form/Input'
import PanelContainer from '../PanelContainer'

export default function CreateUserPanel() {
  const userRepository = useRepository(UserRepository)
  const queryClient = useQueryClient()
  const { register, handleSubmit, reset } = useForm()

  const createUserMutation = useMutation(data => (
    userRepository.create(data)
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ['users'] })
        reset({
          first_name: '',
          last_name: '',
          email_address: '',
          password: '',
          is_admin: false,
        })
      })
  ))

  return (
    <PanelContainer className='px-1 py-4 bg-white-smoke'>
      <p className='px-4'>
        Note: Any users created here are automatically assigned to the Customer
        you have selected in the dropdown in the top-right
      </p>
      <Form onSubmit={handleSubmit(data => createUserMutation.mutate(data))} horizontal>
        <FormGroup type='vertical' label='First Name'>
          <Input {...register('first_name', { required: true })} />
        </FormGroup>
        <FormGroup type='vertical' label='Last Name'>
          <Input {...register('last_name', { required: true })} />
        </FormGroup>
        <FormGroup type='vertical' label='Email Address'>
          <Input type='email' {...register('email_address', { required: true })} />
        </FormGroup>
        <FormGroup type='vertical' label='Password'>
          <Input type='password' {...register('password', { required: true })} />
        </FormGroup>
        <FormGroup type='vertical' className='items-center w-max' label='Administrator'>
          <Input
            type='checkbox'
            {...register('is_admin')}
            className='flex-grow m-3'
          />
        </FormGroup>
        <FormGroup className='items-end'>
          <Button
            type='submit'
            disabled={createUserMutation.isLoading}
          >
          Add User
          </Button>
        </FormGroup>
      </Form>
    </PanelContainer>
  )
}
