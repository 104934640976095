import { Link } from 'react-router-dom'
import useAssetSearch from '../../Hooks/useAssetSearch'

export default function SearchHistoryPanel({ history, setValue }) {
  const { setAssetName } = useAssetSearch()

  return (
    <div className='absolute overflow-auto border rounded-2xl border-black border-opacity-60 shadow-md left-0 top-[100%] mt-1 mr-1 w-full bg-white'>
      {history.map((item, key) => (
        <Link
          to={`/reports/search?assetName=${item}`}
          className='block border-b-[1px] border-b-white-smoke p-2 last:border-b-0 last:mb-0 hover:bg-mischka hover:bg-opacity-10'
          onClick={() => {
            setAssetName(item)
            setValue('search', item)
          }}
          key={`asset-search-${key}`}
        >{item}</Link>
      ))}
    </div>
  )
}
