import PanelContainer from '../PanelContainer'
import { ChartBarIcon } from '@heroicons/react/24/outline'
import Row from './Row'

export default function PollAnalysis({ pollAnalysis }) {
  return (
    <PanelContainer className='w-full max-w-[970px] py-4 px-4'>
      <div className="flex text-right font-light">
        <div className="flex flex-col mr-6 text-left w-2/6">
          <div className="flex items-center">
            <div className="font-bold text-xl mr-4">Poll Analysis</div>
            <ChartBarIcon className="w-6 h-6" />
          </div>
          <Row>Number of polls recorded (poll rate)</Row>
          <Row className="pl-4 ">% of polls (Your zone)</Row>
          <Row className="pl-4">% of polls (3rd party)</Row>
          <Row className="pl-4">% of polls (Out of zone)</Row>
          <Row>Number of no polls recorded (poll rate)</Row>
        </div>

        <div className="flex flex-col w-28">
          <div className="font-bold text-xl">AVG</div>
          <Row className="">{pollAnalysis.percentageOfPollsRecorded.avg}%</Row>
          <Row>{pollAnalysis.percentageInZonePolls.avg}%</Row>
          <Row>{pollAnalysis.percentageOf3rdPartyPolls.avg}%</Row>
          <Row>{pollAnalysis.percentageOutOfZonePolls.avg}%</Row>
          <Row>{pollAnalysis.percentageNoPollsRecorded.avg}%</Row>
        </div>

        <div className="flex flex-col w-28">
          <div className="font-bold text-xl">MAX</div>
          <Row>{pollAnalysis.percentageOfPollsRecorded.max}%</Row>
          <Row>{pollAnalysis.percentageInZonePolls.max}%</Row>
          <Row>{pollAnalysis.percentageOf3rdPartyPolls.max}%</Row>
          <Row>{pollAnalysis.percentageOutOfZonePolls.max}%</Row>
          <Row>{pollAnalysis.percentageNoPollsRecorded.max}%</Row>
        </div>

        <div className="flex flex-col w-28">
          <div className="font-bold text-xl">MIN</div>
          <Row>{pollAnalysis.percentageOfPollsRecorded.min}%</Row>
          <Row>{pollAnalysis.percentageInZonePolls.min}%</Row>
          <Row>{pollAnalysis.percentageOf3rdPartyPolls.min}%</Row>
          <Row>{pollAnalysis.percentageOutOfZonePolls.min}%</Row>
          <Row>{pollAnalysis.percentageNoPollsRecorded.min}%</Row>
        </div>
      </div>
    </PanelContainer>
  )
}
