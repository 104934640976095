import { ArrowUpRightIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import PanelContainer from './PanelContainer'

export default function ReportsListItem({ icon, heading, body, url }) {
  return (
    <Link to={url} className='max-w-[540px]'>
      <PanelContainer className='flex items-center justify-between w-full shadow-md px-3 py-4' >
        <div className='flex items-center font-montserrat gap-x-2'>
          {icon}
          <div className='flex flex-col'>
            <h2 className='text-lg font-bold'>{heading}</h2>
            <p className='text-sm'>{body}</p>
          </div>
        </div>

        <div className='flex items-center justify-center w-8 h-8 rounded-full bg-opacity-100 bg-deep-sky-blue'>
          <ArrowUpRightIcon className='w-4 h-4 text-white' />
        </div>
      </PanelContainer>
    </Link>
  )
}
