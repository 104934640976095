import Navbar from '../components/Navbar/Navbar'

export default function NavbarLayout({ children }) {
  return (
    <div className='flex flex-col flex-grow'>
      <Navbar />
      {children}
    </div>
  )
}
