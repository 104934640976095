import { useRef, useState } from 'react'
import { /* Link, */ useNavigate } from 'react-router-dom'
import Button from '../components/Form/Button'
import Input from '../components/Form/Input'
import LoginPanel from '../components/LoginPanel/LoginPanel'
import LoginPanelFooter from '../components/LoginPanel/LoginPanelFooter'
import LoginPanelForm from '../components/LoginPanel/LoginPanelForm'
import LoginPanelHeader from '../components/LoginPanel/LoginPanelHeader'
import LoginPanelLoadingSpinner from '../components/LoginPanel/LoginPanelLoadingSpinner'
import SiriusSenseLogo from '../components/SiriusSenseLogo'
import useAuth from '../Hooks/useAuth'
import useRepository from '../Hooks/useRepository'
import AuthRepository from '../Repository/AuthRepository'

export default function EntryPage() {
  const authRepository = useRepository(AuthRepository)
  const { setUser } = useAuth()
  const navigateTo = useNavigate()

  const emailAddressRef = useRef()
  const passwordRef = useRef()

  const [hasError, setHasError] = useState(false)
  const [formSubmitting, setFormSubmitting] = useState(false)

  const doLogin = async (e) => {
    e.preventDefault()

    // Disable page controls to stop double clicks and such
    setFormSubmitting(true)

    // Make sure error messages are cleared
    setHasError(false)

    const emailAddress = emailAddressRef.current.value
    const password = passwordRef.current.value

    if (emailAddress.trim().length === 0 || password.trim().length === 0) {
      setHasError(true)
      setFormSubmitting(false)
      return
    }

    authRepository.login(emailAddress, password)
      .then(user => {
        setUser(user)
        navigateTo('/')
      }).catch(() => setHasError(true))
      .finally(() => setFormSubmitting(false))

    // Make sure page doesn't refresh on form submit
    return false
  }

  return (
    <LoginPanel>
      <LoginPanelHeader>Log in to <SiriusSenseLogo colourClass='text-black' /></LoginPanelHeader>

      <LoginPanelForm onSubmit={doLogin}>
        <Input ref={emailAddressRef} placeholder='Email' />
        <Input ref={passwordRef} type='password' placeholder='Password' />
        {hasError && <p className='text-sm text-danger font-semibold'>Username or password not recognised</p>}
        <Button type='submit' disabled={formSubmitting}>Log In</Button>
      </LoginPanelForm>

      { formSubmitting && <LoginPanelLoadingSpinner /> }

      {/* <div className='flex text-sm gap-7'>
        <Link to='/forgotten-password' className='underline'>Forgot password?</Link>
        <span className='underline'>Can't login?</span>
      </div> */}

      <LoginPanelFooter />
    </LoginPanel>
  )
}
