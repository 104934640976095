import AssetSearchResultsTable from '../AssetSearchResultsTable'
import Pagination from '../Pagination'

export default function AssetSearchResults({ searchResults, changePage }) {
  const onNextPageClick = () => {
    changePage(searchResults.meta.current_page + 1)
  }

  const onPrevPageClick = () => {
    changePage(searchResults.meta.current_page - 1)
  }

  if (searchResults.meta.total === 0) {
    return (
      <p>No results</p>
    )
  }

  return (
    <>
      <p className='my-4'>{searchResults.meta
        ? <>Showing records {searchResults.meta.from} to {searchResults.meta.to} of {searchResults.meta.total}</>
        : 'No results'}
      </p>

      <AssetSearchResultsTable results={searchResults.data} />

      <Pagination
        onPrevPageClick={onPrevPageClick}
        onNextPageClick={onNextPageClick}
        currentPage={searchResults.meta?.current_page}
        lastPage={searchResults.meta?.last_page}
      />
    </>
  )
}
