import DashboardCard from '../../Card/DashboardCard'

export default function PolledWithin7Days({ value, percentage }) {
  return (
    <DashboardCard
      value={value}
      description='Polled within 7 days'
      backgroundColour='bg-success'
      valueColour='text-white'
      textColour='text-white'
      buttonColour='bg-white'
      percentage={percentage}
      url='/reports/search?pingLast7Days=true'
    />
  )
}
