import { Link, useMatch } from 'react-router-dom'

export default function NavbarItem({
  label,
  link
}) {
  const isActive = useMatch(`${link}/`)
  return (
    <Link to={link}>
      <div className={`flex cursor-pointer font-montserrat text-center py-4 mx-5 border-transparent border-y-4 ${isActive ? 'font-bold tracking-tight border-t-aggressive-red' : 'font-normal'}`}>
        {label}
      </div>
    </Link>
  )
}
