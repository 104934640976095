import { forwardRef } from 'react'

function Dropdown({ options, className = '', onChange = () => {}, hasBorder, ...props }, ref) {
  return (
    <div className={`cursor-pointer text-sm rounded-[4px] border-opacity-25 border-shady-lady ${className} ${hasBorder ? 'border' : 'py-[1px]'}`}>
      <select
        ref={ref}
        className='p-[9px] rounded-[4px] outline-none cursor-pointer w-full'
        onChange={onChange}
        {...props}
      >
        {options.map((option, key) => {
          return (
            <option key={`dropdown-${key}`} value={option.value} {...option.props ?? null}>{option.text}</option>
          )
        })}
      </select>
    </div>
  )
}

export default forwardRef(Dropdown)
