import PollDate30 from '../components/Table/PollDateBubbles/PollDate30'
import PollDate7 from '../components/Table/PollDateBubbles/PollDate7'
import PollDateOver30 from '../components/Table/PollDateBubbles/PollDateOver30'

export default function createPollDateBubble(diff) {
  if (diff <= 7) {
    return <PollDate7 />
  }
  if (diff <= 30) {
    return <PollDate30 />
  }

  if (isNaN(diff)) {
    return 'N/A'
  }

  return <PollDateOver30 />
}
