import Card from './Card'
import DashboardCardBody from './cardComponents/DashboardCardBody'
import DashboardCardHeader from './cardComponents/DashboardCardHeader'

export default function AssetHistoryCard({
  backgroundColour,
  className,
  value,
  valueColour,
  description,
  textColour,
}) {
  return (
    <Card backgroundColour={backgroundColour} className={className}>
      <DashboardCardHeader value={value} valueColour={valueColour} />
      <DashboardCardBody description={description} textColour={textColour} />
    </Card>
  )
}
