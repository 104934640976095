import useRepository from '../../Hooks/useRepository'
import Button from '../Form/Button'
import OrganisationRepository from '../../Repository/OrganisationRepository'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import Input from '../Form/Input'
import FormGroup from '../Form/FormGroup'
import { useForm } from 'react-hook-form'
import PageMainForm from '../PageMainForm'

export default function CreateOrganisationForm() {
  const queryClient = useQueryClient()
  const organisationRepository = useRepository(OrganisationRepository)
  const { register, handleSubmit, reset } = useForm()

  const createOrganisationMutation = useMutation(data => {
    organisationRepository.create(data)
      .then(() => {
        queryClient.invalidateQueries(['organisations'])
        reset({
          name: '',
          address: '',
        })
      })
  })

  return (
    <PageMainForm horizontal onSubmit={handleSubmit(data => createOrganisationMutation.mutate(data))}>
      <FormGroup
        label='Organisation Name'
        className='basis-1/4'
        type='vertical'
      >
        <Input {...register('name')} />
      </FormGroup>
      <FormGroup
        label='Full Organisation Address'
        className='basis-1/3'
        type='vertical'
      >
        <Input {...register('address')} />
      </FormGroup>
      <FormGroup className='self-end' type='vertical'>
        <Button type='submit'>Add organisation</Button>
      </FormGroup>
    </PageMainForm>
  )
}
