import { ArchiveBoxArrowDownIcon, ArchiveBoxIcon } from '@heroicons/react/24/outline'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import calcDiffInDays from '../functions/calcDiffInDays'
import createDateDiffText from '../functions/createDateDiffText'
import formatLastPolledTime from '../functions/formatLastPolledTime'
import formatSiteDistance from '../functions/formatSiteDistance'
import useAuth from '../Hooks/useAuth'
import useRepository from '../Hooks/useRepository'
import AssetRepository from '../Repository/AssetRepository'
import NeverPolled from './AssetHistory/Cards/NeverPolled'
import Offline from './AssetHistory/Cards/Offline'
import PolledOver30Days from './AssetHistory/Cards/PolledOver30Days'
import PolledWithin30Days from './AssetHistory/Cards/PolledWithin30Days'
import PolledWithin7Days from './AssetHistory/Cards/PolledWithin7Days'
import Button from './Form/Button'
import MapLink from './MapLink'
import PanelContainer from './PanelContainer'

const renderCard = (diff, isOffline, assetName) => {
  if (isOffline) {
    return <Offline value={assetName} />
  }

  if (diff <= 7) {
    return <PolledWithin7Days value={assetName} />
  }

  if (diff <= 30) {
    return <PolledWithin30Days value={assetName} />
  }

  if (isNaN(diff)) {
    return <NeverPolled value={assetName} />
  }

  return <PolledOver30Days value={assetName} />
}

export default function AssetInfo({ asset }) {
  const diff = calcDiffInDays(asset.last_update_time)
  const assetRepository = useRepository(AssetRepository)
  const queryClient = useQueryClient()
  const { isAdminUser } = useAuth()

  const offlineMutation = useMutation(state => (
    ((state === true)
      ? assetRepository.decommission(asset.device_id)
      : assetRepository.recommission(asset.device_id)
    ).then(() => {
      queryClient.invalidateQueries(['asset', asset.device_id])
    })
  ))

  return (
    <PanelContainer className='flex items-start px-3 lg:basis-1/2 overflow-visible'>
      <div className='flex flex-col justify-between h-full'>
        {renderCard(diff, asset.offline, asset.name)}
        { isAdminUser &&
          <div className='pb-4'>
            {asset.offline
              ? <Button className='whitespace-nowrap !px-2 w-full flex gap-2 !bg-primary' onClick={() => offlineMutation.mutate(false)}><ArchiveBoxIcon className='w-6 h-6' /> Recomission</Button>
              : <Button className='whitespace-nowrap !px-2 w-full flex gap-2' onClick={() => offlineMutation.mutate(true)}><ArchiveBoxArrowDownIcon className='w-6 h-6' /> Decommission</Button>
            }
          </div>
        }
      </div>
      <div className='flex flex-col w-full p-3 font-light gap-y-3'>
        <div className='flex gap-x-4'>
          <div className='basis-1/3'>
            <p className='font-bold'>TRACKER</p>
            <p>{asset.device_id}</p>
          </div>

          <div className='basis-2/3'>
            <p className='font-bold whitespace-nowrap'>LAST PING</p>
            {isNaN(diff)
              ? <p>N/A</p>
              : <>
                <p>{formatLastPolledTime(asset.last_update_time)}</p>
                <p>{createDateDiffText(diff)}</p>
              </>
            }
          </div>
        </div>

        <div className='flex gap-x-4'>
          <div className='basis-1/3'>
            <p className='font-bold whitespace-nowrap'>BIRTH DATE</p>
            <p>{dayjs(asset.birth_date).format('DD MMM YYYY')}</p>
          </div>

          <div className='basis-2/3'>
            <p className='font-bold'>LOCATION</p>
            {asset.address
              ? <MapLink latitude={asset.latitude} longitude={asset.longitude} name={asset.name} text={asset.address} />
              : 'N/A'
            }
          </div>
        </div>

        <div className='flex gap-x-4'>
          <div className='basis-1/3'>
            <p className='font-bold'>BROADCAST</p>
            <p>
              {asset.polling_time
                ? <>Daily, {dayjs(asset.expected_polling_time).format('HH:mma')}</>
                : 'Unknown'
              }
            </p>
          </div>

          <div className='basis-2/3'>
            <p className='font-bold whitespace-nowrap'>LAST OFFICIAL ZONE</p>
            <p>{asset.nearest_site_name ?? 'N/A'}</p>
            <div className='flex'>
              <p>{formatSiteDistance(asset.nearest_site_dist)}</p>
              {/* <p>{asset.nearest_site_date}</p> */}
            </div>
          </div>
        </div>

        {isAdminUser && (<>
          <div className='flex gap-x-4'>
            <div className='basis-1/3'>
              <p className='font-bold whitespace-nowrap'>TEMPERATURE</p>
              <p>{asset.temperature ?? 'N/A'}</p>
            </div>

            <div className='basis-1/3'>
              <p className='font-bold'>SPEED</p>
              <p>{asset.speed ?? 'N/A'}</p>
            </div>

            <div className='basis-1/3'>
              <p className='font-bold whitespace-nowrap'>ALTITUDE</p>
              <p>{asset.altitude ?? 'N/A'}</p>
            </div>
          </div>
        </>) }
      </div>
    </PanelContainer>
  )
}
