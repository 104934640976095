import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/24/outline'
import Button from './Form/Button'

export default function Pagination({
  onPrevPageClick,
  onNextPageClick,
  currentPage,
  lastPage
}) {
  return (
    <p className='my-4 px-4 flex justify-center gap-4'>
      <Button
        onClick={onPrevPageClick}
        className='flex'
        disabled={currentPage === 1}
      >
        <ChevronDoubleLeftIcon className='mr-4 w-6 h-6' />
        Previous
      </Button>
      <Button
        onClick={onNextPageClick}
        className='flex'
        disabled={currentPage === lastPage}
      >
        Next
        <ChevronDoubleRightIcon className='ml-4 w-6 h-6' />
      </Button>
    </p>
  )
}
