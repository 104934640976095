import { DocumentDuplicateIcon } from '@heroicons/react/24/outline'
import { useMatch } from 'react-router'
import SidebarItem from '../SidebarItem'

export default function ReportsItem() {
  const isActive = useMatch('/reports/*')
  return (
    <SidebarItem
      icon={<DocumentDuplicateIcon />}
      label="Reports"
      link="/reports"
      isActive={isActive}
    />
  )
}
