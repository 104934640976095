import NavbarItem from '../Navbar/NavbarItem'
import Searchbar from './Searchbar'
import LogOutButton from './LogOutButton'
import CustomerViewDropdown from './CustomerViewDropdown'
import useAuth from '../../Hooks/useAuth'
import ToggleMenuButton from './ToggleMenuButton'
import useBreadcrumbs from '../../Hooks/useBreadcrumbs'

export default function Navbar({ items }) {
  const { isAdminUser } = useAuth()
  const { breadcrumbs } = useBreadcrumbs()

  return (
    <nav className='flex items-center justify-between w-full px-10 border border-opacity-50 shadow-md border-shady-lady'>
      <ToggleMenuButton />

      <div className='flex flex-grow'>
        {breadcrumbs?.map((item, key) => (
          <NavbarItem key={`navbar-item-${key}`} label={item.label} link={item.link} />
        ))}
      </div>

      <div className='flex items-center gap-x-4 py-2'>
        {isAdminUser &&
          <CustomerViewDropdown />
        }
        <Searchbar />
        <LogOutButton />
      </div>
    </nav>
  )
}
