import { useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

export default function DatePicker({ label, value, onChange, onBlur, name }) {
  const [selectedDate, setSelectedDate] = useState(value)

  const handleOnChange = (value) => {
    setSelectedDate(value)
    onChange(value)
  }

  return (
    <div>
      {
        label &&
        <p className='font-semibold text-fiord'>{label}</p>
      }
      <div className='flex'>
        <ReactDatePicker
          className='p-2 border rounded-md border-mischka'
          selected={selectedDate}
          onChange={handleOnChange}
          dateFormat='dd MMM yyyy'
          onBlur={onBlur}
          name={name} />
      </div>
    </div>
  )
}
