import { PencilSquareIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'
import ButtonSquare from '../Form/SquareButton'
import PanelContainer from '../PanelContainer'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableHeader from '../Table/TableHeader'
import TableHeading from '../Table/TableHeading'
import TableRow from '../Table/TableRow'

export default function OrganisationsTable({ organisations }) {
  const navigateTo = useNavigate()

  if (organisations.length === 0) {
    return (
      <p>No results</p>
    )
  }

  return (
    <div className='flex flex-col gap-6'>
      <p>Showing records 1 to {organisations.length}</p>

      <PanelContainer>
        <Table>
          <TableHeader>
            <TableHeading className='text-center'>ID</TableHeading>
            <TableHeading>Customer</TableHeading>
            <TableHeading>Organisation Name</TableHeading>
            <TableHeading>Address</TableHeading>
            <TableHeading className='text-center'>Sites</TableHeading>
            <TableHeading/>
          </TableHeader>

          <tbody>
            {organisations.map((organisation, key) => (
              <TableRow key={`organisation-row-${key}`}>
                <TableCell className='text-center'>{organisation.id}</TableCell>
                <TableCell>{organisation.customer_name}</TableCell>
                <TableCell>{organisation.name}</TableCell>
                <TableCell className='whitespace-pre-line max-w-[440px]'>
                  {organisation.address}
                </TableCell>
                <TableCell className='text-center'>{organisation.site_count}</TableCell>
                <TableCell className='text-right'>
                  <ButtonSquare
                    onClick={() => navigateTo(`/organisations/${organisation.id}/edit`)}
                  >
                    <PencilSquareIcon className='w-6 h-6' />
                  </ButtonSquare>
                </TableCell>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </PanelContainer>
    </div>
  )
}
