import { useEffect, useState } from 'react'
import AssetSummaryList from '../../components/AssetsByOrganisation/AssetSummaryList'
import OrganisationSelectForm from '../../components/AssetsByOrganisation/OrganisationSelectForm'
import useCustomerId from '../../Hooks/useCustomerId'
import useBreadcrumbs from '../../Hooks/useBreadcrumbs'
import usePageTitle from '../../Hooks/usePageTitle'

export default function ByOrganisationPage() {
  const [organisationId, setOrganisationId] = useState('')
  const { customerId } = useCustomerId()

  useBreadcrumbs([
    { label: 'Report menu', link: '/reports' },
    { label: 'Assets by Organisation', link: '/reports/by-organisation' },
  ])

  useEffect(() => {
    setOrganisationId('')
  }, [customerId])

  usePageTitle(
    'Assets by organisation/site',
    'Shows number of Assets by last known Site'
  )

  return <>
    <OrganisationSelectForm organisationId={organisationId} onChangeOrganisation={setOrganisationId} />
    {organisationId && <AssetSummaryList organisationId={organisationId} />}
  </>
}
