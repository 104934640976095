import { DocumentDuplicateIcon } from '@heroicons/react/24/outline'
import DashboardCard from '../../Card/DashboardCard'

export default function AllAssetsCard({ value }) {
  return (
    <DashboardCard
      icon={<DocumentDuplicateIcon />}
      value={value}
      description='All Pallets'
      backgroundColour='bg-royal-blue'
      valueColour='text-white'
      textColour='text-white'
      buttonColour='bg-white'
      url='/reports/search'
    />
  )
}
