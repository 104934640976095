import { MapPinIcon } from '@heroicons/react/24/outline'
import React from 'react'
import PanelContainer from './PanelContainer'
import TableHeading from './Table/TableHeading'
import TableRow from './Table/TableRow'
import Table from './Table/Table'
import TableHeader from './Table/TableHeader'
import TableCell from './Table/TableCell'
import createDateDiffText from '../functions/createDateDiffText'
import createPollDateBubble from '../functions/createPollDateBubble'
import createZoneTypeBubble from '../functions/createZoneTypeBubble'
import MapLink from './MapLink'
import useAuth from '../Hooks/useAuth'
import calcDiffInDays from '../functions/calcDiffInDays'
import formatSiteDistance from '../functions/formatSiteDistance'
import formatLastPolledTime from '../functions/formatLastPolledTime'

export default function AssetHistoryTable({ asset, history }) {
  const { isAdminUser } = useAuth()

  if (history.length === 0) {
    return (
      <p className='mx-1 my-6 font-lato'>No results</p>
    )
  }

  return (
    <>
      <p className='mx-1 my-6 font-lato'>Showing records 1 to {history.length}</p>
      <PanelContainer>
        <Table>
          <TableHeader>
            <TableHeading>
              <p className='ml-8'>Status</p>
            </TableHeading>

            <TableHeading>
              <p className='inline-block'>Last Ping</p>
            </TableHeading>

            <TableHeading>
              <p className='inline-block'>Last Location</p>
              <MapPinIcon className='inline-block w-6 h-6 ml-4' />
            </TableHeading>

            { isAdminUser && <>
              <TableHeading><abbr title='Temperature'>Temp.</abbr></TableHeading>
              <TableHeading><abbr title='Altitude'>Alt.</abbr></TableHeading>
              <TableHeading>Speed</TableHeading>
            </> }

            <TableHeading>
            </TableHeading>

            <TableHeading>
              <p>In a Zone</p>
            </TableHeading>
          </TableHeader>

          <tbody>
            {history.map((record, key) => {
              const diff = calcDiffInDays(record.last_update_time)

              return (
                <TableRow key={`asset-history-row-${key}`}>
                  <TableCell className='pl-12'>
                    {createPollDateBubble(diff)}
                  </TableCell>
                  <TableCell>
                    {formatLastPolledTime(record.last_update_time)}<br />{createDateDiffText(diff)}
                  </TableCell>
                  <TableCell>
                    <MapLink latitude={record.latitude} longitude={record.longitude} name={asset.name} text={record.address} />
                  </TableCell>

                  { isAdminUser && <>
                    <TableCell>{record.temperature}</TableCell>
                    <TableCell>{record.altitude}</TableCell>
                    <TableCell>{record.speed}</TableCell>
                  </>}

                  <TableCell>
                    {createZoneTypeBubble(record.is_in_zone, record.is_third_party_zone)}
                  </TableCell>
                  <TableCell>
                    {record.nearest_site_name ?? 'N/A'}
                    {record.nearest_site_name !== null && <><br />{formatSiteDistance(record.nearest_site_dist)}</>}
                  </TableCell>
                </TableRow>
              )
            })}
          </tbody>
        </Table>
      </PanelContainer>
    </>
  )
}
