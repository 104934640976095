import React, { useState } from 'react'

export const ShowNavContext = React.createContext({
  showNav: true
})

export default function ShowNavProvider({ children }) {
  const isMobile = window.innerWidth <= 768

  // Default to hide nav on mobile devices ("small screens")
  const [showNav, setShowNav] = useState(!isMobile)

  const toggleShowNav = () => {
    // Toggling nav only works on mobile
    if (!isMobile) {
      return
    }

    setShowNav(v => !v)
  }

  const value = {
    showNav,
    toggleShowNav,
  }

  return (
    <ShowNavContext.Provider value={value}>
      {children}
    </ShowNavContext.Provider>
  )
}
