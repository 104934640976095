import { useQuery } from '@tanstack/react-query'
import CreateUserPanel from '../../components/Users/CreateUserPanel'
import UserTable from '../../components/Users/UserTable'
import useRepository from '../../Hooks/useRepository'
import UserRepository from '../../Repository/UserRepository'
import LoadingSpinner from '../../components/LoadingSpinner'
import useBreadcrumbs from '../../Hooks/useBreadcrumbs'
import usePageTitle from '../../Hooks/usePageTitle'

export default function UsersAdminPage() {
  const userRepository = useRepository(UserRepository)

  const { data: users, isLoading, isError } = useQuery({
    queryKey: ['users'],
    queryFn: () => userRepository.list()
  })

  useBreadcrumbs([
    { label: 'Admin', link: '/admin' },
    { label: 'User admin', link: '/admin/users' }
  ])

  usePageTitle(
    'Admin > User management',
    'Add and edit users from the system'
  )

  return <>
    <CreateUserPanel />

    {isLoading
      ? <p className="font-semibold"><LoadingSpinner /> Fetching data...</p>
      : (isError
        ? <p>ERROR</p>
        : <UserTable users={users} />
      )
    }
  </>
}
