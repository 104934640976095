import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import Button from '../../components/Form/Button'
import Form from '../../components/Form/Form'
import FormGroup from '../../components/Form/FormGroup'
import Input from '../../components/Form/Input'
import PanelContainer from '../../components/PanelContainer'
import useRepository from '../../Hooks/useRepository'
import OrganisationRepository from '../../Repository/OrganisationRepository'
import LoadingSpinner from '../../components/LoadingSpinner'
import useBreadcrumbs from '../../Hooks/useBreadcrumbs'
import usePageTitle from '../../Hooks/usePageTitle'

export default function EditOrganisationPage() {
  const organisationRepository = useRepository(OrganisationRepository)
  const queryClient = useQueryClient()
  const navigateTo = useNavigate()
  const { id: organisationId } = useParams()
  const { register, handleSubmit } = useForm()

  const { data: organisation, isLoading, isError } = useQuery({
    queryKey: ['organisation', organisationId],
    queryFn: () => organisationRepository.get(organisationId)
  })

  const updateOrganisationMutation = useMutation(data => {
    organisationRepository.update(organisationId, data)
      .then(() => {
        queryClient.invalidateQueries(['organisations'])
        queryClient.invalidateQueries(['organisation', organisationId])
        navigateTo('/organisations')
      })
  })

  useBreadcrumbs([
    { label: 'Manage Organisations', link: '/organisations' },
    { label: 'Manage Sites', link: '/organisations/sites' }
  ])

  usePageTitle(
    'Edit Organisation',
    "Update an organisation's details"
  )

  return isLoading
    ? <p className="font-semibold"><LoadingSpinner /> Fetching data...</p>
    : (isError
      ? <p>ERROR</p>
      : <PanelContainer className='max-w-[500px]'>
        <Form onSubmit={handleSubmit(data => updateOrganisationMutation.mutate(data))}>
          <FormGroup label='Organisation Name' type='vertical'>
            <Input {...register('name')} defaultValue={organisation.name} />
          </FormGroup>
          <FormGroup label='Address' type='vertical'>
            <Input {...register('address')} defaultValue={organisation.address} />
          </FormGroup>
          <Button
            className='items-center justify-center'
            loading={updateOrganisationMutation.isLoading}
            type='submit'
          >
            Update
          </Button>
        </Form>
      </PanelContainer>
    )
}
