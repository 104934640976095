import { DocumentDuplicateIcon } from '@heroicons/react/24/outline'
import DashboardCard from '../DashboardCard'

export default function LastViewedAssetCard({ value, showIcon = null, url }) {
  return (
    <DashboardCard
      icon={showIcon ? <DocumentDuplicateIcon /> : null}
      value={value}
      description='Last viewed Asset'
      buttonColour='bg-shady-lady'
      backgroundColour='bg-white'
      url={url}
    />
  )
}
