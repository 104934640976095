import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import AssetSearchProvider from './AssetSearchProvider'
import AuthProvider from './AuthProvider'
import CustomerIdProvider from './CustomerIdProvider'
import ShowNavProvider from './ShowNavProvider'
import BreadcrumbProvider from './BreadcrumbProvider'
import PageTitleProvider from './PageTitleProvider'
import FeatureFlagProvider from './FeatureFlagProvider'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    }
  }
})

export default function ProviderWrapper({ children }) {
  return (
    <FeatureFlagProvider>
      <AuthProvider>
        <BreadcrumbProvider>
          <PageTitleProvider>
            <CustomerIdProvider>
              <ShowNavProvider>
                <AssetSearchProvider>
                  <QueryClientProvider client={queryClient}>
                    {children}
                  </QueryClientProvider>
                </AssetSearchProvider>
              </ShowNavProvider>
            </CustomerIdProvider>
          </PageTitleProvider>
        </BreadcrumbProvider>
      </AuthProvider>
    </FeatureFlagProvider>
  )
}
