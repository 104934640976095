import DashboardCard from '../../Card/DashboardCard'

export default function AllAssets({ value, percentage }) {
  return (
    <DashboardCard
      value={value}
      description='All Pallets'
      backgroundColour='bg-royal-blue'
      valueColour='text-white'
      textColour='text-white'
      buttonColour='bg-white'
      percentage={percentage}
      url='/reports/search'
    />
  )
}
