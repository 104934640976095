
export default function AssetRepository(client) {
  const get = (assetId) => (
    client.get(`api/asset/${assetId}`)
      .then(response => response.data?.data)
  )

  const history = (assetId, numDays = 7) => (
    client.get(`api/asset/${assetId}/history/${numDays}`)
      .then(response => response.data)
      .then(data => data.data)
  )

  const createNote = (assetId, note) => (
    client.post(`api/asset/${assetId}/note`, {
      note
    })
  )

  const search = (params) => {
    const queryString = new URLSearchParams(params)
    return client.get(`api/reports/search?${queryString}`)
      .then(response => response.data)
  }

  const decommission = (assetId) => (
    client.delete(`api/asset/${assetId}`)
  )

  const recommission = (assetId) => (
    client.put(`api/asset/${assetId}`)
  )

  const getFavourites = () => (
    client.get('/api/assets/favourites')
      .then(response => response.data)
  )

  const addFavourite = assetId => (
    client.put(`/api/assets/favourites/${assetId}`)
  )

  const removeFavourite = assetId => (
    client.delete(`/api/assets/favourites/${assetId}`)
  )

  const generateFleetUsageReport = (params) => {
    const queryString = new URLSearchParams(params)
    return client.get(`api/reports/usage-fleet-report?${queryString}`)
      .then(response => response.data)
  }

  return {
    createNote,
    get,
    history,
    search,
    decommission,
    recommission,
    getFavourites,
    addFavourite,
    removeFavourite,
    generateFleetUsageReport,
  }
}
