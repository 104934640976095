import { useContext, useEffect } from 'react'
import { BreadcrumbContext } from '../Providers/BreadcrumbProvider'

export default function useBreadcrumbs(breadcrumbs = []) {
  const { breadcrumbs: storedBreadcrumbs, setBreadcrumbs } = useContext(BreadcrumbContext)

  useEffect(() => {
    setBreadcrumbs(breadcrumbs)
    return () => setBreadcrumbs([])
  }, [])

  return { breadcrumbs: storedBreadcrumbs }
}
