/**
 * Returns whether the given date falls on a weekend day
 * @param {dayjs} date
 * @returns bool
 */
export default function isWeekendDay(date) {
  // 0 = Sunday
  // 6 = Saturday
  return [0, 6].includes(date.day())
}
