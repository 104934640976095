import AssetHistoryCard from '../../Card/AssetHistoryCard'

export default function PolledWithin30Days({ value }) {
  return (
    <AssetHistoryCard
      className='pb-4'
      backgroundColour='bg-warning'
      value={value}
      valueColour='text-white'
      description='Polled within 30 days'
      textColour='text-white'
    />
  )
}
