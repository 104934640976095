import { HeartIcon } from '@heroicons/react/24/outline'
import DashboardCard from '../DashboardCard'

export default function WatchListCard({ value, showIcon = null }) {
  return (
    <DashboardCard
      icon={showIcon ? <HeartIcon className='text-aggressive-red' /> : null}
      value={value}
      description={
        <>
          <span>My watch list Pallets</span>
          <br/>
          <br/>
        </>
      }
      buttonColour='bg-shady-lady'
      backgroundColour='bg-white'
      url='/reports/search/?watchlist=true'
    />
  )
}
