import calcDiffInDays from './calcDiffInDays'

export default function createSinceBirthStatistic(daysChanged, birthDate) {
  // +1 offset as we consider the day it was created to be a possible possible day
  const daysSinceBirth = calcDiffInDays(birthDate) + 1
  const pollsOutOfDays = daysChanged + '/' + daysSinceBirth
  const pollPercentage = '(' + Math.ceil(daysChanged / daysSinceBirth * 100) + '%)'

  return pollsOutOfDays + ' ' + pollPercentage
}
