import LoadingSpinner from '../LoadingSpinner'

export default function Button({
  children,
  disabled,
  loading,
  type = 'button',
  onClick = () => {},
  className = '',
  ...props
}) {
  return (
    <button
      className={`px-2 md:px-16 py-2 text-buttonText bg-buttonBackground rounded-[4px] ${disabled ? 'bg-secondary cursor-not-allowed' : ''} ${className}`}
      disabled={disabled || loading}
      onClick={onClick}
      type={type}
      {...props}
    >
      {loading ? <LoadingSpinner className='!mx-0' /> : <>{children}</>}
    </button>
  )
}
