
export default function DashboardRepository(client) {
  const loadData = () => (
    client.get('api/dashboard')
      .then(response => response.data)
  )

  const getLastViewedAssetName = () => {
    const lastViewed = localStorage.getItem('lastViewedAsset')
    return lastViewed !== null
      ? JSON.parse(lastViewed)
      : null
  }

  const setLastViewedAssetName = (assetId, assetName) => (
    localStorage.setItem('lastViewedAsset', JSON.stringify({
      id: assetId,
      name: assetName
    }))
  )

  return {
    loadData,
    getLastViewedAssetName,
    setLastViewedAssetName,
  }
}
