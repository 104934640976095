import { MapPinIcon } from '@heroicons/react/24/outline'
import ReportsListItem from '../ReportsListItem'

export default function ZoneMonitorReportLink({ url }) {
  return (
    <ReportsListItem
      icon={<MapPinIcon className='w-11 h-11' />}
      heading='Pallets out of zone monitor'
      body='Pallets that have moved out of zone or back in'
      url='/reports/zone-monitor'
    />
  )
}
