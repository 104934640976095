
export default function Card({ children, backgroundColour = '', className = '' }) {
  return (
    <div
      className={`flex flex-col p-3 my-3 border-mischka border border-opacity-10 rounded-2xl shadow-md w-full max-w-[136px] ${backgroundColour} ${className}`}
    >
      {children}
    </div>
  )
}
