import { useQueries, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import UsageReportForm from '../../components/UsageFleetReport/UsageReportForm'
import UsageFleetReportResults from '../../components/UsageFleetReport/UsageFleetReportResults'
import LoadingSpinner from '../../components/LoadingSpinner'
import convertToBoolean from '../../functions/convertToBoolean'
import useRepository from '../../Hooks/useRepository'
import AssetRepository from '../../Repository/AssetRepository'
import useBreadcrumbs from '../../Hooks/useBreadcrumbs'
import usePageTitle from '../../Hooks/usePageTitle'
import useCustomerId from '../../Hooks/useCustomerId'

export default function UsageFleetReportPage() {
  const assetRepository = useRepository(AssetRepository)
  const [searchParams, setSearchParams] = useSearchParams()
  const [formData, setFormData] = useState({
    periodStartDate: searchParams.get('periodStartDate') ?? '',
    periodEndDate: searchParams.get('periodEndDate') ?? '',
    excludeOffline: convertToBoolean(searchParams.get('excludeOffline') ?? 'true'),
  })
  const { register, handleSubmit, setValue } = useForm({ defaultValues: formData })
  const [hasSearched, setHasSearched] = useState(false)
  const { customerId } = useCustomerId()
  const queryClient = useQueryClient()

  useEffect(() => {
    const existingStartDate = formData.periodStartDate
    const existingEndDate = formData.periodEndDate
    const startDateFromParams = searchParams.get('periodStartDate')
    const endDateFromParams = searchParams.get('periodEndDate')

    if (startDateFromParams && !existingStartDate) {
      setValue('periodStartDate', startDateFromParams)
    }

    if (endDateFromParams && !existingEndDate) {
      setValue('periodEndDate', endDateFromParams)
    }
  })

  const handleFormData = (data) => {
    setSearchParams(data)
    setFormData(data)
    setHasSearched(true)
  }

  const [
    { data: searchResults, isLoading, isError },
  ] = useQueries({
    queries: [
      {
        queryKey: ['fleetStatistics', customerId, formData],
        queryFn: () => assetRepository.generateFleetUsageReport(formData),
        enabled: hasSearched,
      },
    ],
    queryClient,
  })

  useBreadcrumbs([
    { label: 'Report menu', link: '/reports' },
    { label: 'Usage fleet report', link: '/reports/usage-fleet-report' },
  ])

  usePageTitle('Usage fleet report', 'Statistical overview of fleet (filterable by date)')

  return (
    <>
      <UsageReportForm
        setValue={setValue}
        handleSubmit={handleSubmit(handleFormData)}
        register={register}
        isLoading={isLoading}
        hasSearched={hasSearched}
      />

      {hasSearched // if user has submitted the form
        ? isLoading // check if we are loading
          ? (
            <p>
              <LoadingSpinner /> Loading...
            </p>
          )
          : isError // check if we have erorr
            ? (
              <p>ERROR</p>
            )
            // not loading and no error, display query results
            : <UsageFleetReportResults searchResults={searchResults}/>
        : <></> // default case, show nothing
      }
    </>
  )
}
