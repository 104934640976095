import { useQueries } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import AssetSearchForm from '../../components/AssetSearch/AssetSearchForm'
import AssetSearchResults from '../../components/AssetSearch/AssetSearchResults'
import LoadingSpinner from '../../components/LoadingSpinner'
import convertToBoolean from '../../functions/convertToBoolean'
import useRepository from '../../Hooks/useRepository'
import AssetRepository from '../../Repository/AssetRepository'
import SiteRepository from '../../Repository/SiteRepository'
import { DEFAULT_SITE_TEXT } from '../../data/constants'
import useBreadcrumbs from '../../Hooks/useBreadcrumbs'
import usePageTitle from '../../Hooks/usePageTitle'

export default function AssetSearchPage() {
  const assetRepository = useRepository(AssetRepository)
  const siteRepository = useRepository(SiteRepository)
  const [searchParams, setSearchParams] = useSearchParams()
  const [formData, setFormData] = useState({
    inZone: convertToBoolean(searchParams.get('inZone')),
    outOfZone: convertToBoolean(searchParams.get('outOfZone')),
    offline: convertToBoolean(searchParams.get('offline')),
    pingLast7Days: convertToBoolean(searchParams.get('pingLast7Days')),
    pingLast30Days: convertToBoolean(searchParams.get('pingLast30Days')),
    pingOver30Days: convertToBoolean(searchParams.get('pingOver30Days')),
    watchlist: convertToBoolean(searchParams.get('watchlist')),
    site: searchParams.get('site') ?? DEFAULT_SITE_TEXT,
    assetName: searchParams.get('assetName') ?? '',
    eolStartDate: searchParams.get('eolStartDate') ?? '',
    eolEndDate: searchParams.get('eolEndDate') ?? '',
  })
  const { register, handleSubmit, reset, setValue } = useForm({ defaultValues: formData })

  useEffect(() => {
    const assetName = searchParams.get('assetName') ?? ''
    if (assetName !== formData.assetName) {
      setFormData(f => ({ ...f, assetName }))
      setValue('assetName', assetName)
    } else {
      setFormData(f => ({ ...f }))
    }
  }, [searchParams.get('assetName')])

  const handleFormData = (data) => {
    setSearchParams(data)
    setFormData(data)
  }

  const [
    { data: searchResults, isLoading, isError },
    { data: sites }
  ] = useQueries({
    queries: [{
      queryKey: ['assets', formData],
      queryFn: () => assetRepository.search(formData)
    }, {
      queryKey: ['sites'],
      queryFn: () => siteRepository.list(),
      onSuccess: () => {
        reset({
          site: searchParams.get('site') ?? DEFAULT_SITE_TEXT
        })
      }
    }]
  })

  useBreadcrumbs([
    { label: 'Report menu', link: '/reports' },
    { label: 'Asset Search', link: '/reports/search' }
  ])

  const changePage = (newPageNumber) => {
    searchParams.append('page', newPageNumber)
    setFormData(fd => ({ ...fd, page: newPageNumber }))
  }

  usePageTitle(
    'All Pallets',
    'Filterable report showing all Pallets regardless of status'
  )

  return <>
    <AssetSearchForm
      handleSubmit={handleSubmit(handleFormData)}
      register={register}
      defaultSiteText={DEFAULT_SITE_TEXT}
      sites={sites}
      isLoading={isLoading}
    />

    { isLoading
      ? <p><LoadingSpinner />Loading...</p>
      : (isError
        ? <p>ERROR</p>
        : <AssetSearchResults
          searchResults={searchResults}
          changePage={changePage}
        />
      )
    }
  </>
}
