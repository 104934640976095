import { ChevronDownIcon, ChevronUpDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import TableHeading from './TableHeading'

export default function SortableTableHeading({ children, sortName, sortingBy, sortingDirection, onSort }) {
  return (
    <TableHeading>
      <div className='flex justify-between'>
        <span>{children}</span>
        <span className='underline cursor-pointer' onClick={() => onSort(sortName)}>
          { sortingBy === sortName
            ? (sortingDirection === 'asc'
              ? <ChevronDownIcon className='w-6 h-6' />
              : <ChevronUpIcon className='w-6 h-6' />
            )
            : <ChevronUpDownIcon className='w-6 h-6' />
          }
        </span>
      </div>
    </TableHeading>
  )
}
