import React, { useMemo } from 'react'
import { GoogleMap, useLoadScript, MarkerF } from '@react-google-maps/api'

export default function Map({ latitude, longitude, deviceName }) {
  const { isLoaded } = useLoadScript({ googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY })
  const center = useMemo(() => ({ lat: latitude, lng: longitude }), [latitude, longitude])

  if (!isLoaded) return <div>Loading...</div>

  return (
    <GoogleMap zoom={10} center={center} mapContainerClassName="w-full h-[100vh]">
      <MarkerF title="trackerPosition" label={deviceName} position={center} />
    </GoogleMap>
  )
}
