import { MapPinIcon } from '@heroicons/react/24/outline'
import ReportsListItem from '../ReportsListItem'

export default function ByOrganisationReportLink() {
  return (
    <ReportsListItem
      icon={<MapPinIcon className='w-11 h-11' />}
      heading='Pallets by organisation/site'
      body='List of pallets where the site(s) were the last polled location'
      url='/reports/by-organisation'
    />
  )
}
