import { forwardRef } from 'react'

function Checkbox({ label, onChange, ...props }, ref) {
  return (
    <label className='p-2'>
      <input type="checkbox" className='mx-2' ref={ref} onChange={onChange} {...props} />
      {label}
    </label>
  )
}

export default forwardRef(Checkbox)
