import { ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router'
import useRepository from '../../Hooks/useRepository'
import AuthRepository from '../../Repository/AuthRepository'

export default function LogOutButton() {
  const navigateTo = useNavigate()
  const authRepository = useRepository(AuthRepository)

  const doLogout = () => {
    authRepository.logout()
    navigateTo('/login')
  }

  return (
    <button onClick={doLogout} title='Logout' >
      <ArrowRightOnRectangleIcon className='w-8 h-8' />
    </button>
  )
}
