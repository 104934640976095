export default function CustomerRepository(client) {
  const list = () => (
    client.get('api/customers')
      .then(response => response.data?.data)
  )

  const create = (data) => (
    client.post('/api/customers', data)
  )

  const get = (id) => (
    client.get(`/api/customers/${id}`)
      .then(response => response.data?.data)
  )

  const update = (customerId, data) => (
    client.patch(`/api/customers/${customerId}`, data)
  )

  return {
    list,
    create,
    get,
    update
  }
}
