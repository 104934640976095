export default function createBackgroundClassFromText(text) {
  switch (text) {
  case 'success':
    return 'bg-success'
  case 'warning':
    return 'bg-warning'
  case 'danger':
    return 'bg-danger'
  default:
    return ''
  }
}
