import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router'
import Button from '../../components/Form/Button'
import FormGroup from '../../components/Form/FormGroup'
import Input from '../../components/Form/Input'
import LoadingSpinner from '../../components/LoadingSpinner'
import usePageTitle from '../../Hooks/usePageTitle'
import PageMainForm from '../../components/PageMainForm'
import useRepository from '../../Hooks/useRepository'
import CustomerRepository from '../../Repository/CustomerRepository'
import useBreadcrumbs from '../../Hooks/useBreadcrumbs'

export default function EditCustomerPage() {
  const navigateTo = useNavigate()
  const customerRepository = useRepository(CustomerRepository)
  const queryClient = useQueryClient()
  const { register, handleSubmit } = useForm()
  const { id: customerId } = useParams()

  const { isLoading, isError, data: customer } = useQuery({
    queryKey: ['customers', customerId],
    queryFn: () => customerRepository.get(customerId),
  })

  const updateCustomerMutation = useMutation(data => (
    customerRepository.update(customerId, data)
      .then(() => {
        queryClient.invalidateQueries('customers')
        navigateTo('/admin/customers')
      })
  ))

  useBreadcrumbs([
    { label: 'Admin', link: '/admin' },
    { label: 'Customer admin', link: '/admin/customers' }
  ])

  usePageTitle('Customers', 'Customer management page')

  return <>

    { isLoading
      ? <p className="font-semibold"><LoadingSpinner /> Fetching data...</p>
      : (isError
        ? <p>ERROR</p>
        : <PageMainForm
          className='max-w-[500px]'
          onSubmit={handleSubmit(data => updateCustomerMutation.mutate(data))}
        >
          <FormGroup type='vertical' label='Customer Name' className='pb-4'>
            <Input type='text'
              {...register('name', { required: true })}
              defaultValue={customer.name}
            />
          </FormGroup>
          <FormGroup type='vertical' label='Flespi Group ID' className='pb-4'>
            <Input type='text'
              {...register('flespi_id')}
              defaultValue={customer.flespi_group_id}
            />
          </FormGroup>
          <FormGroup type='vertical' label='Asset Movement Tolerance (meters)' className='pb-4'>
            <Input type='text'
              {...register('asset_movement_tolerance')}
              defaultValue={customer.asset_movement_tolerance}
            />
          </FormGroup>
          <Button type='submit'>
              Update customer
          </Button>
        </PageMainForm>
      )
    }
  </>
}
