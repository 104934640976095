import { useQuery } from '@tanstack/react-query'
import useRepository from '../../Hooks/useRepository'
import OrganisationRepository from '../../Repository/OrganisationRepository'
import Dropdown from '../Dropdown'
import FormGroup from '../Form/FormGroup'
import PageMainForm from '../PageMainForm'

export default function OrganisationSelectForm({ organisationId, onChangeOrganisation }) {
  const organisationRepository = useRepository(OrganisationRepository)

  const { data: organisations } = useQuery({
    queryKey: ['organisations'],
    queryFn: organisationRepository.list,
    onSuccess: (response) => {
      const firstOrganisationId = response[0].id ?? null
      onChangeOrganisation(firstOrganisationId)
    }
  })

  return (
    <PageMainForm horizontal>
      <FormGroup label='Organisation' labelClassName='pr-4'>
        <Dropdown
          options={[{
            text: 'Select an Organisation',
            props: {
              disabled: true
            }
          },
          ...(organisations ?? []).map(org => ({
            text: org.name,
            value: org.id
          }))]}
          onChange={e => onChangeOrganisation(e.currentTarget.value)}
          value={organisationId}
        />
      </FormGroup>
    </PageMainForm>
  )
}
