import React from 'react'
import Map from '../components/Map'
import { useSearchParams } from 'react-router-dom'

export default function MapPage() {
  const [searchParams] = useSearchParams()

  const latitude = searchParams.get('latitude')
  const longitude = searchParams.get('longitude')
  const deviceName = searchParams.get('device_name')

  if (isNaN(latitude) || isNaN(longitude) || deviceName === null || deviceName === '') {
    return (
      <div className='flex items-center justify-center mx-auto'>
        Unexpected Error. Sorry.
      </div>
    )
  }

  return (
    <Map
      latitude={parseFloat(latitude)}
      longitude={parseFloat(longitude)}
      device_name={deviceName}
    />
  )
}
