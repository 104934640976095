import PanelContainer from '../PanelContainer'
import { Battery50Icon } from '@heroicons/react/24/outline'
import Row from './Row'

export default function BatteryUsageAnalysis({ batteryUsageAnalysis }) {
  return (
    <PanelContainer className='w-full max-w-[970px] py-4 px-4'>
      <div className="flex text-right font-light">
        <div className="flex flex-col mr-6 text-left w-2/6">
          <div className="flex items-center">
            <div className="font-bold text-xl mr-4">Battery Usage Analysis</div>
            <Battery50Icon className="w-6 h-6" />
          </div>
          <Row>Battery % decrease</Row>
          <Row>Battery % used per day</Row>
        </div>

        <div className="flex flex-col w-28">
          <div className="font-bold text-xl">AVG</div>
          <Row>{batteryUsageAnalysis.batteryDecrease.avg}</Row>
          <Row>{batteryUsageAnalysis.batteryUsedPerDay.avg}</Row>
        </div>

        <div className="flex flex-col w-28">
          <div className="font-bold text-xl">MAX</div>
          <Row>{batteryUsageAnalysis.batteryDecrease.max}</Row>
          <Row>{batteryUsageAnalysis.batteryUsedPerDay.max}</Row>
        </div>

        <div className="flex flex-col w-28">
          <div className="font-bold text-xl">MIN</div>
          <Row>{batteryUsageAnalysis.batteryDecrease.min}</Row>
          <Row>{batteryUsageAnalysis.batteryUsedPerDay.min}</Row>
        </div>
      </div>
    </PanelContainer>
  )
}
