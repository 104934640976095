import { MapPinIcon } from '@heroicons/react/24/outline'
import { useMatch } from 'react-router'
import SidebarItem from '../SidebarItem'

export default function OrganisationsItem() {
  const isActive = useMatch('/organisations/*')
  return (
    <SidebarItem
      icon={<MapPinIcon />}
      label="Organisations"
      link="/organisations"
      isActive={isActive}
    />
  )
}
