import { MapPinIcon } from '@heroicons/react/24/outline'
import ReportsListItem from '../ReportsListItem'

export default function AssetTimelineReportLink() {
  return (
    <ReportsListItem
      icon={<MapPinIcon className='w-11 h-11' />}
      heading='Pallet movements timeline'
      body='Pallet movements across a defined period of time'
      url='/reports/asset-timeline'
    />
  )
}
