
export default function ReportRepository(client) {
  const loadOutOfZoneMonitorReport = (reportName, param) => {
    return client.get(`api/reports/out-of-zone-monitor${param?.fixDate ? '/' + param?.fixDate : ''}?report=${reportName}&filterFavourite=${param.filterByFavourite}`)
      .then(response => response.data.data)
  }

  const loadAssetsByOrganisation = (organisationId) => (
    organisationId === undefined
      ? {}
      : client.get(`api/reports/by-organisation/${organisationId}`)
        .then(response => response.data)
  )

  const loadAssetTimeline = (days, page) => (
    client
      .get(`/api/reports/asset-timeline?days=${days}&page=${page}`)
      .then(response => response.data)
  )

  return {
    loadOutOfZoneMonitorReport,
    loadAssetsByOrganisation,
    loadAssetTimeline,
  }
}
