import AssetsByOrganisationCard from './AssetsByOrganisationCard'

export default function PolledWithin7Days({ siteId, value }) {
  return (
    <AssetsByOrganisationCard
      value={value}
      description='Polled within 7 days'
      colour='text-success'
      url={`/reports/search?pingLast7Days=true&site=${siteId}`}
    />
  )
}
