import useAuth from '../Hooks/useAuth'

export default function AuthRepository(client) {
  const { setUser } = useAuth()

  const login = (emailAddress, password) => {
    /**
     * A consession is being made here for now, by awaiting for responses
     * it's easier than handling promises and asynchronous code when the
     * calling function needs to know whether to show an error message
     */
    return client
      .get('sanctum/csrf-cookie')
      .then(() => client.post(
        'api/login',
        {
          email_address: emailAddress,
          password
        }
      )).then(response => response.data)
      .then(data => data.user)
  }

  const logout = () => {
    // We don't really care when this API call returns, all it does
    // is destroys the session on the server and returns an empty body
    client
      .post('api/logout')
      .then(() => setUser(null))
  }

  const getUser = async() => {
    const response = await client.get('api/user')
    const data = await response.data

    return data
  }

  return {
    login,
    logout,
    getUser
  }
}
