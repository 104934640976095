import PollAnalysis from './PollAnalysis'
import PollTimingAnalysis from './PollTimingAnalysis'
import BatteryUsageAnalysis from './BatteryUsageAnalysis'
import LocationAnalysis from './LocationAnalysis'
import Forecasting from './Forecasting'
import BatteryFailPeriod from './BatteryFailPeriod'
import { TagIcon, CalendarDaysIcon } from '@heroicons/react/24/outline'
import DashboardCard from '../../components/Card/DashboardCard'

export default function UsageFleetReportResults({ searchResults }) {
  return (
    <>
      <div className="flex gap-x-4 mb-[-10px] mt-[-30px]">
        <div className="flex flex-col">
          <DashboardCard
            icon={<TagIcon />}
            value={searchResults.numberOfAssetsAnalysed}
            description='Number of assets analysed'
            backgroundColour='bg-royal-blue'
            valueColour='text-white'
            textColour='text-white'
          />
        </div>
        <div className="flex flex-col">
          <DashboardCard
            icon={<CalendarDaysIcon />}
            value={searchResults.numberOfDaysAnalysed}
            description='Number of days analysed'
            backgroundColour='bg-success'
            valueColour='text-white'
            textColour='text-white'
          />
        </div>
      </div>

      <PollAnalysis pollAnalysis = {searchResults.pollAnalysis}/>
      <PollTimingAnalysis pollTimingAnalysis = {searchResults.pollTimingAnalysis}/>
      <BatteryUsageAnalysis batteryUsageAnalysis = {searchResults.batteryUsageAnalysis}/>
      <LocationAnalysis locationAnalysis = {searchResults.locationAnalysis}/>
      <Forecasting forecastingAnalysis = {searchResults.forecastingAnalysis}/>
      <BatteryFailPeriod batteryFailPeriod = {searchResults.batteryFailPeriod} />

    </>
  )
}
