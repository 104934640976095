import CreateOrganisationForm from '../../components/Organisations/CreateOrganisationForm'
import OrganisationsTable from '../../components/Organisations/OrganisationsTable'
import useRepository from '../../Hooks/useRepository'
import OrganisationRepository from '../../Repository/OrganisationRepository'
import { useQuery } from '@tanstack/react-query'
import LoadingSpinner from '../../components/LoadingSpinner'
import useBreadcrumbs from '../../Hooks/useBreadcrumbs'
import usePageTitle from '../../Hooks/usePageTitle'

export default function OrganisationsPage() {
  const organisationRepository = useRepository(OrganisationRepository)

  const { data: organisations, isLoading, isError } = useQuery({
    queryKey: ['organisations'],
    queryFn: () => organisationRepository.list()
  })

  useBreadcrumbs([
    { label: 'Manage Organisations', link: '/organisations' },
    { label: 'Manage Sites', link: '/organisations/sites' }
  ])

  usePageTitle('Organisations', 'Manage organisations and associated sites')

  return <>
    <CreateOrganisationForm />

    {isLoading
      ? <p className="font-semibold"><LoadingSpinner /> Fetching data...</p>
      : (isError
        ? <p>ERROR</p>
        : <OrganisationsTable organisations={organisations} />
      )
    }
  </>
}
