import './index.css'
import 'react-tooltip/dist/react-tooltip.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import ProviderWrapper from './Providers/ProviderWrapper'
import MapPage from './pages/MapPage'
import DashboardPage from './pages/DashboardPage'
import LoginPage from './pages/LoginPage'
import ReportsPage from './pages/ReportsPage'
import OrganisationsPage from './pages/Organisations/OrganisationsPage'
import EditOrganisationPage from './pages/Organisations/EditOrganisationPage'
import AdminPage from './pages/Admin/AdminPage'
import OutOfZoneMonitorPage from './pages/Reports/OutOfZoneMonitorPage'
import CustomerPage from './pages/Admin/CustomerPage'
import EditCustomerPage from './pages/Admin/EditCustomerPage'
import UsersAdminPage from './pages/Admin/UsersAdminPage'
import EditUserAdminPage from './pages/Admin/EditUserAdminPage'
import AssetHistoryPage from './pages/AssetHistoryPage'
import AssetSearchPage from './pages/Reports/AssetSearchPage'
import UsageFleetReportPage from './pages/Reports/UsageFleetReportPage'
import EditSitePage from './pages/Sites/EditSitePage'
import SitesPage from './pages/Sites/SitesPage'
import ProtectedPage from './pages/Admin/ProtectedPage'
import ByOrganisationPage from './pages/Reports/ByOrganisationPage'
import AssetTimelinePage from './pages/Reports/AssetTimelinePage'
import AppLayout from './Layouts/AppLayout'

export default function App() {
  return (
    <Router>
      <ProviderWrapper>
        <Routes>
          <Route path='/' element={<AppLayout />}>
            <Route index element={<DashboardPage />} />

            <Route path="/reports" >
              <Route index element={<ReportsPage />} />
              <Route path="zone-monitor" element={<OutOfZoneMonitorPage />} />
              <Route path='asset-history/:asset_id' element={<AssetHistoryPage />} />
              <Route path='search' element={<AssetSearchPage />} />
              <Route path='by-organisation' element={<ByOrganisationPage />} />
              <Route path='asset-timeline' element={<AssetTimelinePage />} />
              <Route path='usage-fleet-report' element={<ProtectedPage page={<UsageFleetReportPage />} />} />
            </Route>

            <Route path="/organisations">
              <Route index element={<OrganisationsPage />} />
              <Route path="sites">
                <Route index element={<SitesPage />} />
                <Route path=":id/edit" element={<EditSitePage />} />
              </Route>
              <Route path=":id/edit" element={<EditOrganisationPage />} />
            </Route>

            <Route path="/admin">
              <Route index element={<ProtectedPage page={<AdminPage />} />} />
              <Route path="customers" >
                <Route index element={<ProtectedPage page={<CustomerPage />} />} />
                <Route path=":id/edit" element={<ProtectedPage page={<EditCustomerPage />} />} />
              </Route>
              <Route path="users" >
                <Route index element={<ProtectedPage page={<UsersAdminPage />} />} />
                <Route path=":id/edit" element={<ProtectedPage page={<EditUserAdminPage />} />} />
              </Route>
            </Route>
          </Route>

          <Route path="/map" element={<MapPage />} />
          <Route path="/login" element={<LoginPage />} />

          <Route path="*" /> {/* errorpage */}
        </Routes>
      </ProviderWrapper>
    </Router>
  )
}
