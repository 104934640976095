import React, { useEffect, useState } from 'react'
import useAuth from '../Hooks/useAuth'

export const CustomerIdContext = React.createContext()

export default function CustomerIdProvider({ children }) {
  /**
   * TODO What I'd like to do is expand on this so it fetches the customer data
   * like Organisations and Sites so we can just drop those on any forms as we
   * go. But there is no time left.
   */

  const { user } = useAuth()
  const presetCustomerId = localStorage.getItem('x-customer-id')
  const [selectedCustomerId, _setSelectedCustomerId] = useState(presetCustomerId ?? user?.customer_id)

  const setSelectedCustomerId = (newId) => {
    _setSelectedCustomerId(newId)
    localStorage.setItem('x-customer-id', newId)
  }

  useEffect(() => {
    if (!selectedCustomerId && user?.customer_id) {
      setSelectedCustomerId(user.customer_id)
    }
  }, [user?.customer_id])

  const value = {
    customerId: selectedCustomerId,
    setCustomerId: setSelectedCustomerId
  }

  return (
    <CustomerIdContext.Provider value={value}>
      {children}
    </CustomerIdContext.Provider>
  )
}
