import { Navigate } from 'react-router'
import useAuth from '../../Hooks/useAuth'

export default function ProtectedPage({ page }) {
  const { isAdminUser } = useAuth()

  if (!isAdminUser) {
    return <Navigate to='/' replace />
  }

  return page
}
