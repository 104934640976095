import isWeekendDay from '../../functions/isWeekendDay'
import TableHeader from '../Table/TableHeader'
import TableHeading from '../Table/TableHeading'

export default function ResultsTableHeader({ dateRange }) {
  return (
    <TableHeader>
      <TableHeading>Asset</TableHeading>
      {dateRange.map((date, key) => {
        return (
          <TableHeading
            key={key}
            className={`w-[3rem] text-sm ${isWeekendDay(date) ? 'bg-mischka bg-opacity-10' : ''}`}
          >
            {date.format('DD/MM')}
          </TableHeading>
        )
      })}
    </TableHeader>
  )
}
