import dayjs from 'dayjs'
import formatAssetName from '../../functions/formatAssetName'
import Dropdown from '../Dropdown'
import Pagination from '../Pagination'
import ResultsTable from './ResultsTable'

export default function ResultsPanel({
  paginator,
  historyResults,
  days,
  setDays,
  setPage
}) {
  /**
   * We start this journey with 2 lists: one of Asset names (in paginator.data)
   * and one of individual records in historyResults.  We want to build a single
   * structure grouped by Asset name so we can easily iterate over them later
   */
  const tableData = paginator.data.reduce((acc, asset) => {
    return [
      ...acc,
      {
        device_name: formatAssetName(asset.device_name),
        asset_id: asset.device_id,
        records: historyResults
          .filter(res => res.device_name === asset.device_name)
          .map(record => ({
            date: record.polling_date,
            data: record
          }))
      }
    ]
  }, [])

  // Create a list of all dates that should be shown in this view
  const dateRange = Array(days).fill(0).map((_, index) => dayjs().subtract(index, 'days')).reverse()

  return (
    <>
      <div className='flex justify-between py-4'>
        <Dropdown
          className='border'
          options={[
            { text: 'Last 7 days', value: 7 },
            { text: 'Last 14 days', value: 14 },
            { text: 'Last 30 days', value: 30 },
            { text: 'Last 45 days', value: 45 },
          ]}
          onChange={e => setDays(Number(e.currentTarget.value))}
        />
      </div>

      <p className='font-lato'>Showing records {paginator.from} to {paginator.to} of {paginator.total}</p>

      <ResultsTable dateRange={dateRange} results={tableData} />

      <Pagination
        onPrevPageClick={() => setPage(p => p - 1)}
        onNextPageClick={() => setPage(p => p + 1)}
        currentPage={paginator.current_page}
        lastPage={paginator.last_page}
      />
    </>
  )
}
