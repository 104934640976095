import { HeartIcon } from '@heroicons/react/24/outline'
import ReportsListItem from '../ReportsListItem'

export default function WatchlistReportLink() {
  return (
    <ReportsListItem
      icon={<HeartIcon className='w-11 h-11' />}
      heading='My watch list pallets'
      body='Pallets that have been added to your watch list'
      url='/reports/search/?watchlist=true'
    />
  )
}
