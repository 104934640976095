import { useQuery } from '@tanstack/react-query'
import CreateCustomerForm from '../../components/Customers/CreateCustomerForm'
import CustomersTable from '../../components/Customers/CustomersTable'
import LoadingSpinner from '../../components/LoadingSpinner'
import useRepository from '../../Hooks/useRepository'
import CustomerRepository from '../../Repository/CustomerRepository'
import useBreadcrumbs from '../../Hooks/useBreadcrumbs'
import usePageTitle from '../../Hooks/usePageTitle'

export default function CustomerPage() {
  const customerRepository = useRepository(CustomerRepository)

  useBreadcrumbs([
    { label: 'Admin', link: '/admin' },
    { label: 'Customer admin', link: '/admin/customers' }
  ])

  const { data: customers, isLoading, isError } = useQuery({
    queryKey: ['customers'],
    queryFn: () => customerRepository.list(),
  })

  usePageTitle('Customers', 'Customer management page')

  return <>
    <CreateCustomerForm />

    {isLoading
      ? <p className="font-semibold"><LoadingSpinner /> Fetching data...</p>
      : (isError
        ? <p>ERROR</p>
        : <CustomersTable customers={customers} />
      )
    }
  </>
}
