export default function convertToReadableTime(seconds) {
  seconds = parseFloat(seconds)
  if (seconds >= 60) {
    return minutesAndSeconds(seconds)
  } else if (seconds >= 10) {
    return Math.round(seconds) + ' seconds'
  } else if (seconds < 0.01) {
    return 'less than 0.01 seconds'
  } else {
    return seconds.toPrecision(2) + ' seconds'
  }
}

function minutesAndSeconds(seconds) {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60

  return minutes + ' minutes ' + Math.round(remainingSeconds) + ' seconds'
}
