import AssetsByOrganisationCard from './AssetsByOrganisationCard'

export default function InZone({ siteId, value }) {
  return (
    <AssetsByOrganisationCard
      value={value}
      description='Assets in zone'
      colour='text-success'
      url={`/reports/search?inZone=true&site=${siteId}`}
    />
  )
}
