import dayjs from 'dayjs'
import { forwardRef } from 'react'
import Button from './Form/Button'
import PanelContainer from './PanelContainer'

function Notes({ notes, onSubmit, isLoading, isError }, ref) {
  return (
    <PanelContainer className='flex flex-col p-4 pt-2 basis-1/2'>
      <p className='px-1 pb-1 font-bold'>Notes</p>
      <div className='flex gap-x-4'>
        <div className='resize-none grow'>
          <textarea className='h-full p-2 w-full overflow-y-auto font-light border-2 rounded-md border-opacity-20 border-mischka' placeholder='Enter a note here' ref={ref}></textarea>
          {isError && <p className="text-sm text-danger">Something went wrong saving the note</p>}
        </div>
        <div className='self-end'>
          <Button onClick={() => onSubmit()} disabled={isLoading} loading={isLoading} className='w-full justify-center'>Add note</Button>
        </div>
      </div>
      <div className='mx-1 my-2 font-light'>
        {notes.map((note, key) => {
          return (
            <div className='flex gap-x-3 text-sm' key={`asset-history-note-${key}`}>
              <p className='basis-1/5'>{dayjs(note.created_at).format('D MMM YYYY HH:mm')}</p>
              <p className='basis-3/5'>{note.note}</p>
              <p className='basis-1/5 text-right'>{note.user.first_name} {note.user.last_name}</p>
            </div>
          )
        })}
      </div>
    </PanelContainer>
  )
}

export default forwardRef(Notes)
