import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import useRepository from '../../Hooks/useRepository'
import CustomerRepository from '../../Repository/CustomerRepository'
import Button from '../Form/Button'
import Form from '../Form/Form'
import FormGroup from '../Form/FormGroup'
import Input from '../Form/Input'
import PanelContainer from '../PanelContainer'

export default function CreateCustomerForm() {
  const { register, handleSubmit, reset } = useForm()
  const customerRepository = useRepository(CustomerRepository)
  const queryClient = useQueryClient()

  const createCustomerMutation = useMutation(data => (
    customerRepository.create(data)
      .then(() => {
        queryClient.invalidateQueries('customers')
        reset({
          name: '',
          flespi_id: '',
        })
      })
  ))

  return (
    <PanelContainer className=' bg-white-smoke'>
      <Form onSubmit={handleSubmit(data => createCustomerMutation.mutate(data))} horizontal>
        <FormGroup type='vertical'
          label='Customer Name'
          className='w-full max-w-[500px]'>
          <Input type='text'
            {...register('name', { required: true })}/>
        </FormGroup>
        <FormGroup type='vertical'
          label='Flespi group ID'
          className='w-full max-w-[250px]'>
          <Input type='number'
            {...register('flespi_id')}/>
        </FormGroup>
        <Button type='submit' className='self-end' loading={createCustomerMutation.isLoading}>
          Create Customer
        </Button>
      </Form>
    </PanelContainer>
  )
}
