export default function SiteRepository(client) {
  const list = () => (
    client.get('api/sites')
      .then(response => response.data.data)
  )

  const create = (data) => (
    client.post('api/sites', data)
  )

  const get = (id) => (
    client.get(`api/sites/${id}`)
      .then(response => response.data?.data)
  )

  const update = (siteId, data) => (
    client.patch(`api/sites/${siteId}`, data)
  )

  return {
    list,
    create,
    get,
    update
  }
}
