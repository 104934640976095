import useRepository from '../../Hooks/useRepository'
import SiteRepository from '../../Repository/SiteRepository'
import { useQuery } from '@tanstack/react-query'
import SiteTable from '../../components/Sites/SiteTable'
import CreateSiteForm from '../../components/Sites/CreateSiteForm'
import LoadingSpinner from '../../components/LoadingSpinner'
import useBreadcrumbs from '../../Hooks/useBreadcrumbs'
import usePageTitle from '../../Hooks/usePageTitle'

export default function SitesPage() {
  const siteRepository = useRepository(SiteRepository)

  const { data: sites, isLoading, isError } = useQuery({
    queryKey: ['sites'],
    queryFn: () => siteRepository.list(),
  })

  useBreadcrumbs([
    { label: 'Manage Organisations', link: '/organisations' },
    { label: 'Manage Sites', link: '/organisations/sites' }
  ])

  usePageTitle('Manage sites', 'Manage the sites where pallets may be transferred between')

  return <>
    <CreateSiteForm />

    {isLoading
      ? <p className="font-semibold"><LoadingSpinner /> Fetching data...</p>
      : (isError
        ? <p>ERROR</p>
        : <SiteTable data={sites} />
      )
    }
  </>
}
