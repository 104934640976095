import PanelContainer from '../PanelContainer'
import { ClockIcon } from '@heroicons/react/24/outline'
import Row from './Row'

export default function PollTimingAnalysis({ pollTimingAnalysis }) {
  return (
    <PanelContainer className='w-full max-w-[970px] py-4 px-4'>
      <div className="flex text-right font-light">
        <div className="flex flex-col flex-grow mr-6 text-left max-w-[310px]">
          <div className="flex items-center">
            <div className="font-bold text-xl mr-4">Poll Timing Analysis</div>
            <ClockIcon className="w-6 h-6" />
          </div>
          <Row>% polled within</Row>
          <Row className="mt-auto"></Row>
          <Row>Awake time (mins) across period</Row>
        </div>

        <div className="flex flex-col w-28">
          <div className="font-bold text-xl">1st min</div>
          <Row>{pollTimingAnalysis.percentagePolledWithin.firstMinutePercentage}%</Row>
          <div className="font-bold text-xl mt-6">AVG</div>
          <Row>{pollTimingAnalysis.awakeTimeMinutes.avg}</Row>
        </div>

        <div className="flex flex-col w-28">
          <div className="font-bold text-xl">2nd min</div>
          <Row>{pollTimingAnalysis.percentagePolledWithin.secondMinutePercentage}%</Row>
          <div className="font-bold text-xl mt-6">MAX</div>
          <Row>{pollTimingAnalysis.awakeTimeMinutes.max}</Row>
        </div>

        <div className="flex flex-col w-28">
          <div className="font-bold text-xl">3rd min</div>
          <Row>{pollTimingAnalysis.percentagePolledWithin.thirdMinutePercentage}%</Row>
          <div className="font-bold text-xl mt-6">MIN</div>
          <Row>{pollTimingAnalysis.awakeTimeMinutes.min}</Row>
        </div>

        <div className="flex flex-col w-28">
          <div className="font-bold text-xl">4th min</div>
          <Row>{pollTimingAnalysis.percentagePolledWithin.forthMinutePercentage}%</Row>
        </div>

        <div className="flex flex-col w-28 ml-2">
          <div className="font-bold text-xl">5th min+</div>
          <Row>{pollTimingAnalysis.percentagePolledWithin.fifthMinutePercentage}%</Row>
        </div>

        <div className="flex flex-col w-28">
          <div className="font-bold text-xl">No Poll</div>
          <Row>{pollTimingAnalysis.percentagePolledWithin.noPoll}%</Row>
        </div>
      </div>
    </PanelContainer>
  )
}
