import React from 'react'
import Input from './Input'

function DecimalInput({ ...props }, ref) {
  return (
    <Input type='number' step='any' {...props} ref={ref} />
  )
}

export default React.forwardRef(DecimalInput)
