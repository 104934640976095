import { MapPinIcon } from '@heroicons/react/24/outline'
import ReportsListItem from '../ReportsListItem'

export default function AssetSearchReportLink() {
  return (
    <ReportsListItem
      icon={<MapPinIcon className='w-11 h-11' />}
      heading='All Pallets (filterable)'
      body='Filterable report showing all Pallets regardless of status'
      url='/reports/search'
    />
  )
}
