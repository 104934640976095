import ExternalLink from './ExternalLink'

export default function MapLink({ name, latitude, longitude, text }) {
  return (
    <ExternalLink
      href={`/map?latitude=${latitude}&longitude=${longitude}&device_name=${name}`}
    >
      {text}
    </ExternalLink>
  )
}
