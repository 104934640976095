
export default function NavbarInput({ icon, children, className, element: Element = 'div', ...props }) {
  return (
    <Element
      className={`flex gap-1 items-center px-3 py-[6px] border border-black border-opacity-60 rounded-full ${className ?? ''}`}
      {...props}
    >
      {icon}
      {children}
    </Element>
  )
}
