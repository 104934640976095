import ZoneMonitorTable from '../../components/ZoneMonitor/ZoneMonitorTable'
import ZoneMonitorBubbleKey from '../../components/ZoneMonitor/ZoneMonitorBubbleKey'
import DatePicker from '../../components/DatePicker'
import useRepository from '../../Hooks/useRepository'
import ReportRepository from '../../Repository/ReportRepository'
import React, { useState } from 'react'
import { useQueries } from '@tanstack/react-query'
import LoadingSpinner from '../../components/LoadingSpinner'
import dayjs from 'dayjs'
import useBreadcrumbs from '../../Hooks/useBreadcrumbs'
import usePageTitle from '../../Hooks/usePageTitle'
import Button from '../../components/Form/Button'
import PageMainForm from '../../components/PageMainForm'
import FormGroup from '../../components/Form/FormGroup'
import Checkbox from '../../components/Form/Checkbox'
import { Controller, useForm } from 'react-hook-form'

export default function ZoneMonitorPage() {
  const reportRepository = useRepository(ReportRepository)
  const [formData, setFormData] = useState({
    filterByFavourite: false,
    fixDate: dayjs().subtract(7, 'day').format('YYYY-MM-DD')
  })

  const { register, handleSubmit, control } = useForm()

  useBreadcrumbs([
    { label: 'Report menu', link: '/reports' },
    { label: 'Out of Zone Monitor', link: '/reports/zone-monitor' }
  ])

  const reportQueries = useQueries({
    queries: ['back_in_zone', 'gone_out_of_zone', 'out_of_zone'].map(
      query => ({
        queryKey: ['out-of-zone-monitor', query, formData],
        queryFn: () => reportRepository.loadOutOfZoneMonitorReport(query, formData),
      })
    )
  })

  usePageTitle(
    'Pallets out of zone monitor',
    'Pallets grouped by those that have come back into zone, gone out of zone or are still out of zone based on the fix date'
  )

  const reports = [
    'Pallets that have come back into zone since the fix date',
    'Pallets that have gone out of zone since the fix date',
    'Pallets out of zone since the fix date and still out of zone at last ping',
  ]

  const handleFormData = (data) => {
    setFormData(data)
  }

  return <>
    <div className='flex py-6 gap-x-6'>
      <PageMainForm className="w-full max-w-[27rem]" onSubmit={handleSubmit(handleFormData)}>
        <FormGroup label="Fix Date" labelClassName='w-full max-w-[10rem]'>
          <Controller
            control={control}
            name="fixDate"
            defaultValue={formData.fixDate}
            render={({ field }) => (
              <DatePicker
                value={new Date(field.value)}
                onChange={(data) => field.onChange(dayjs(data).format('YYYY-MM-DD'))}
                onBlur={field.onBlur}
                name={field.name}
              />
            )}
          />
        </FormGroup>
        <FormGroup label="Filter" labelClassName='w-full max-w-[10rem]'>
          <Checkbox label='Watch list assets' {...register('filterByFavourite')}/>
        </FormGroup>
        <FormGroup label={true} labelClassName='w-full max-w-[10rem]'>
          <Button type='submit' loading={false} className='self-start'>Show Results</Button>
        </FormGroup>
      </PageMainForm>
      <div className="my-4">
        <ZoneMonitorBubbleKey />
      </div>
    </div>

    {reports.map((report, key) => {
      const reportQuery = reportQueries[key]

      return (
        <React.Fragment key={key}>
          <h3 className='font-semibold'>{report} {reportQuery.data ? `(${reportQuery.data.length})` : ''}</h3>
          {reportQuery.isLoading
            ? <p className='flex items-center'><LoadingSpinner /> Loading...</p>
            : (reportQuery.isError
              ? <p>ERROR</p>
              : (reportQuery.data.length === 0
                ? <p>No results</p>
                : <ZoneMonitorTable
                  key={`report-${key}`}
                  fixDate={formData.fixDate}
                  data={reportQuery.data}
                />
              )
            )
          }
        </React.Fragment>
      )
    })}
  </>
}
