export default function Form({
  children,
  horizontal = false,
  onSubmit = () => {},
}) {
  return (
    <form
      className={`${horizontal ? 'flex gap-4 px-6 py-4 w-full' : 'flex flex-col gap-2 p-4'}`}
      onSubmit={onSubmit}>
      {children}
    </form>
  )
}
