import useBreadcrumbs from '../Hooks/useBreadcrumbs'
import usePageTitle from '../Hooks/usePageTitle'
import useAuth from '../Hooks/useAuth'
import AssetSearchReportLink from '../components/ReportsPageLinks/AssetSearchReportLink'
import AssetTimelineReportLink from '../components/ReportsPageLinks/AssetTimelineReportLink'
import ByOrganisationReportLink from '../components/ReportsPageLinks/ByOrganisationReportLink'
import WatchlistReportLink from '../components/ReportsPageLinks/WatchlistReportLink'
import ZoneMonitorReportLink from '../components/ReportsPageLinks/ZoneMonitorReportLink'
import UsageFleetReportLink from '../components/ReportsPageLinks/UsageFleetReportLink'

export default function ReportsPage() {
  const { isAdminUser } = useAuth()

  useBreadcrumbs([
    { label: 'Report menu', link: '/reports' }
  ])

  usePageTitle('Reports')

  return <div className='flex flex-col py-6 gap-y-4'>
    <ZoneMonitorReportLink />
    <ByOrganisationReportLink />
    <AssetSearchReportLink />
    <AssetTimelineReportLink />
    { isAdminUser && <UsageFleetReportLink /> }
    <WatchlistReportLink />
  </div>
}
