import AssetsByOrganisationCard from './AssetsByOrganisationCard'

export default function OutOfZone({ siteId, value }) {
  return (
    <AssetsByOrganisationCard
      value={value}
      description='Assets out of zone'
      colour='text-danger'
      url={`/reports/search?outOfZone=true&site=${siteId}`}
    />
  )
}
