
export default function LoginPanelFooter() {
  const version = process.env.REACT_APP_VERSION

  return (
    <div className='text-[10px] mt-8 opacity-80'>
      &copy; Copyright 2023 Sirius Asset Solutions Limited | v{version}
    </div>
  )
}
