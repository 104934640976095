import AssetHistoryCard from '../../Card/AssetHistoryCard'

export default function PolledWithin7Days({ value }) {
  return (
    <AssetHistoryCard
      className='pb-4'
      backgroundColour='bg-success'
      value={value}
      valueColour='text-white'
      description='Polled within 7 days'
      textColour='text-white'
    />
  )
}
