import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { ASSET_SEARCH_HISTORY_STORAGE_KEY } from '../../data/constants'
import useAssetSearch from '../../Hooks/useAssetSearch'
import NavbarInput from './NavbarInput'
import SearchHistoryPanel from './SearchHistoryPanel'

export default function Searchbar() {
  const { assetName, setAssetName } = useAssetSearch()
  const { register, handleSubmit, setValue } = useForm()
  const navigateTo = useNavigate()
  const [showHistory, setShowHistory] = useState(false)

  const previousSearches = JSON.parse(
    localStorage.getItem(ASSET_SEARCH_HISTORY_STORAGE_KEY) ?? '[]'
  ) ?? []

  const search = (data) => {
    setAssetName(data.search)
    navigateTo(`/reports/search?assetName=${data.search}`)
  }

  /**
   * Dodgy hack time:
   * When the history is open and you click on an item in it, React updates
   * quickly enough that the history list hides before its click handler fires.
   * This means that when you click a history item, the list disappears but the
   * search is not performed.
   * By delaying the hiding, the click handler can happen so the search works
   */
  const hideHistory = () => setTimeout(() => setShowHistory(false), 500)

  return (
    <NavbarInput
      element='form'
      icon={<MagnifyingGlassIcon className='w-5 h-5' />}
      onSubmit={handleSubmit(data => search(data))}
      className='relative' // needed for the absolutely positioned dropdown
    >
      <input
        className='text-black placeholder-black outline-none'
        placeholder='Pallet Search'
        defaultValue={assetName}
        {...register('search', { required: true })}
        onFocus={() => setShowHistory(true)}
        onBlur={() => hideHistory()}
      />

      {showHistory &&
        <SearchHistoryPanel history={previousSearches} setValue={setValue} />
      }
    </NavbarInput>
  )
}
