import AssetsByOrganisationCard from './AssetsByOrganisationCard'

export default function AllAssets({ siteId, value }) {
  return (
    <AssetsByOrganisationCard
      value={value}
      description='All Assets'
      colour='text-primary'
      url={`/reports/search?site=${siteId}`}
    />
  )
}
