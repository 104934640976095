import { MapPinIcon } from '@heroicons/react/24/outline'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import SiteDistances from '../../data/SiteDistances'
import useRepository from '../../Hooks/useRepository'
import SiteRepository from '../../Repository/SiteRepository'
import Dropdown from '../Dropdown'
import Button from '../Form/Button'
import Input from '../Form/Input'
import PageMainForm from '../PageMainForm'
import CheckBox from '../Form/Checkbox'
import useCustomerId from '../../Hooks/useCustomerId'
import OrganisationRepository from '../../Repository/OrganisationRepository'
import FormGroup from '../Form/FormGroup'
import DecimalInput from '../Form/DecimalInput'

export default function CreateSiteForm() {
  const { customerId } = useCustomerId()
  const organisationRepository = useRepository(OrganisationRepository)
  const siteRepository = useRepository(SiteRepository)
  const queryClient = useQueryClient()
  const { register, handleSubmit, reset } = useForm()

  const { data: organisations } = useQuery({
    queryKey: ['organisations', customerId],
    queryFn: () => organisationRepository.list()
  })

  const createSiteMutation = useMutation(data => {
    siteRepository.create(data)
      .then(() => {
        queryClient.invalidateQueries(['sites'])
        reset({
          name: '',
          location: '',
          is_third_party: false,
          zone_size: SiteDistances.default,
          latitude: '',
          longitude: '',
        })
      })
  })

  return (
    <PageMainForm horizontal onSubmit={handleSubmit(data => createSiteMutation.mutate(data))}>
      <FormGroup label='Organisation' type='vertical'>
        <Dropdown
          options={(organisations ?? []).map(org => ({
            text: org.name,
            value: org.id
          }))}
          {...register('organisation_id', { required: true })}
          hasBorder
        />
      </FormGroup>
      <FormGroup label='Site name' type='vertical'>
        <Input {...register('name', { required: true })} />
      </FormGroup>
      <FormGroup label='Site address' type='vertical'>
        <Input {...register('location', { required: true })} />
      </FormGroup>
      <FormGroup label='Third party' type='vertical' className='flex flex-col items-center'>
        <CheckBox {...register('is_third_party')} />
      </FormGroup>
      <FormGroup label='Zone size' type='vertical'>
        <Dropdown
          options={SiteDistances.options}
          defaultValue={SiteDistances.default}
          hasBorder
          {...register('zone_size', { required: true })}
        />
      </FormGroup>
      <FormGroup label='Latitude' type='vertical'>
        <DecimalInput {...register('latitude', { required: true })} />
      </FormGroup>
      <FormGroup label='Longitude' type='vertical'>
        <DecimalInput {...register('longitude', { required: true })} />
      </FormGroup>
      <FormGroup className='self-end' type='vertical'>
        <Button type='submit' className='whitespace-nowrap'>
          <MapPinIcon className='w-6 h-6 inline-block mr-2' />
          Add site
        </Button>
      </FormGroup>
    </PageMainForm>
  )
}
