import Dropdown from '../Dropdown'
import Button from '../Form/Button'
import Checkbox from '../Form/Checkbox'
import FormGroup from '../Form/FormGroup'
import Input from '../Form/Input'
import PageMainForm from '../PageMainForm'

export default function AssetSearchForm({
  handleSubmit,
  register,
  defaultSiteText,
  sites,
  isLoading
}) {
  return (
    <PageMainForm onSubmit={handleSubmit}>
      <FormGroup label='Asset name' labelClassName='w-full max-w-[10rem]'>
        <Input type='text' {...register('assetName')} />
      </FormGroup>
      <FormGroup label='Zone status' labelClassName='w-full max-w-[10rem]'>
        <Checkbox label='In zone' {...register('inZone')} />
        <Checkbox label='Out of zone' {...register('outOfZone')} />
        <Checkbox label='Offline' {...register('offline')} />
      </FormGroup>
      <FormGroup label='Filter' labelClassName='w-full max-w-[10rem]'>
        <Checkbox label='Watch list assets' {...register('watchlist')} />
      </FormGroup>
      <FormGroup label='Last pinged' labelClassName='w-full max-w-[10rem]'>
        <Checkbox label='< 7 days' {...register('pingLast7Days')} />
        <Checkbox label='< 30 days' {...register('pingLast30Days')} />
        <Checkbox label='30 days +' {...register('pingOver30Days')} />
      </FormGroup>
      <FormGroup label='Organisation' labelClassName='w-full max-w-[10rem]'>
        <Dropdown
          options={[
            {
              text: defaultSiteText
            },
            ...(sites ?? []).map(site => ({
              text: site.name,
              value: site.id
            }))]}
          {...register('site')}
        />
      </FormGroup>
      <FormGroup label={true} labelClassName='w-full max-w-[10rem]'>
        <Button type='submit' loading={isLoading} className='self-start'>Show Assets</Button>
      </FormGroup>
    </PageMainForm>
  )
}
