import Sidebar from '../components/Sidebar/Sidebar'

export default function SidebarLayout({ children }) {
  return (
    <div className='flex min-h-screen'>
      <Sidebar />
      {children}
    </div>
  )
}
