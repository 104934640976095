
/**
 * Convenience function to convert 'true' and 'false' correctly to booleans
 * @param {string} str The string to be converted to a Boolean
 * @returns bool
 */
export default function convertToBoolean(str) {
  if (typeof str === 'string' && str.toLowerCase() === 'false') {
    return false
  }

  // This also covers 'true'
  return !!str
}
