import createZoneTypeBubble from '../../functions/createZoneTypeBubble'
import TableCell from '../Table/TableCell'

export default function ResultsTableCell({ record, isWeekendDay }) {
  return (
    <TableCell className={`border-r border-mischka border-opacity-30 ${isWeekendDay ? 'bg-mischka bg-opacity-10' : ''}`}>
      {record === undefined
        ? null
        : <div title={record.address} className='cursor-help'>
          {createZoneTypeBubble(record.is_in_zone, record.is_third_party_zone)}
        </div>
      }
    </TableCell>)
}
