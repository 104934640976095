import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { useParams } from 'react-router'
import LoadingSpinner from '../../components/LoadingSpinner'
import useRepository from '../../Hooks/useRepository'
import UserRepository from '../../Repository/UserRepository'
import EditUserForm from '../../components/Users/EditUserForm'
import useBreadcrumbs from '../../Hooks/useBreadcrumbs'
import usePageTitle from '../../Hooks/usePageTitle'

export default function EditUserAdminPage() {
  const userRepository = useRepository(UserRepository)
  const { id: userId } = useParams()

  const { data: user, isLoading, isError } = useQuery({
    queryKey: ['users', userId],
    queryFn: () => userRepository.get(userId),
  })

  useBreadcrumbs([
    { label: 'Admin', link: '/admin' },
    { label: 'User admin', link: '/admin/users' }
  ])

  usePageTitle(
    'Admin > User management > Edit User',
    'Edit details of a user in the system'
  )

  return isLoading
    ? <p className="font-semibold"><LoadingSpinner /> Fetching data...</p>
    : (isError
      ? <p>ERROR</p>
      : <EditUserForm user={user} />
    )
}
