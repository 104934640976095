import React from 'react'
import { Outlet } from 'react-router'
import SidebarLayout from './SidebarLayout'
import NavbarLayout from './NavbarLayout'
import PageHeader from '../components/PageHeader'

export default function AppLayout() {
  return (
    <SidebarLayout>
      <NavbarLayout>
        <div className='p-8 font-montserrat flex flex-col gap-8 min-w-[768px]'>
          <PageHeader />
          <Outlet />
        </div>
      </NavbarLayout>
    </SidebarLayout>
  )
}
