import { Link } from 'react-router-dom'
import isWeekendDay from '../../functions/isWeekendDay'
import TableBody from '../Table/TableBody'
import TableCell from '../Table/TableCell'
import TableRow from '../Table/TableRow'
import ResultsTableRow from './ResultsTableCell'

export default function ResultsTableBody({ dateRange, results }) {
  return (
    <TableBody>
      {results.map((row, key) => (
        <TableRow key={key}>
          <TableCell className='border-r border-mischka border-opacity-30'>
            <Link className='underline' to={`/reports/asset-history/${row.asset_id}`}>
              {row.device_name}
            </Link>
          </TableCell>
          {dateRange.map((date, key) => {
            const dateStr = date.format('YYYY-MM-DD')

            return (
              <ResultsTableRow
                record={row.records.filter(item => item.date === dateStr)[0]?.data}
                isWeekendDay={isWeekendDay(date)}
                key={key}
              />
            )
          })}
        </TableRow>
      ))}
    </TableBody>
  )
}
