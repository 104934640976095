import React, { useState } from 'react'
import { ASSET_SEARCH_HISTORY_STORAGE_KEY } from '../data/constants'

const MAX_SEARCH_HISTORY = 10

export const AssetSearchContext = React.createContext({})

function maintainAssetSearchHistory(newSearch) {
  // Maintain a history of the last 10 searches to display in the UI
  const previousSearches = JSON.parse(
    localStorage.getItem(ASSET_SEARCH_HISTORY_STORAGE_KEY) ?? '[]'
  ) ?? []

  // Remove the search from the list if it's in there already
  if (previousSearches.includes(newSearch)) {
    previousSearches.splice(previousSearches.findIndex(element => element === newSearch), 1)
  }

  // Add at the beginning of the list so the newest is at the top in the UI
  previousSearches.unshift(newSearch)

  // Remove elements from the end of the list until we only have the max.
  previousSearches.splice(MAX_SEARCH_HISTORY)

  localStorage.setItem(ASSET_SEARCH_HISTORY_STORAGE_KEY, JSON.stringify(previousSearches))
}

export default function AssetSearchProvider({ children }) {
  const [assetName, setAssetNameState] = useState('')

  const setAssetName = (newAssetName) => {
    maintainAssetSearchHistory(newAssetName)
    setAssetNameState(newAssetName)
  }

  const value = {
    assetName,
    setAssetName,
  }

  return (
    <AssetSearchContext.Provider value={value}>
      {children}
    </AssetSearchContext.Provider>
  )
}
