import { useMutation, useQueries, useQueryClient } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router'
import { useForm } from 'react-hook-form'
import Dropdown from '../../components/Dropdown'
import Button from '../../components/Form/Button'
import Form from '../../components/Form/Form'
import FormGroup from '../../components/Form/FormGroup'
import Input from '../../components/Form/Input'
import PanelContainer from '../../components/PanelContainer'
import useRepository from '../../Hooks/useRepository'
import SiteRepository from '../../Repository/SiteRepository'
import SiteDistances from '../../data/SiteDistances'
import LoadingSpinner from '../../components/LoadingSpinner'
import Checkbox from '../../components/Form/Checkbox'
import OrganisationRepository from '../../Repository/OrganisationRepository'
import useCustomerId from '../../Hooks/useCustomerId'
import DecimalInput from '../../components/Form/DecimalInput'
import useBreadcrumbs from '../../Hooks/useBreadcrumbs'
import usePageTitle from '../../Hooks/usePageTitle'

export default function EditSitePage() {
  const navigateTo = useNavigate()
  const siteRepository = useRepository(SiteRepository)
  const queryClient = useQueryClient()
  const { id: siteId } = useParams()
  const { register, handleSubmit } = useForm()
  const organisationRepository = useRepository(OrganisationRepository)
  const { customerId } = useCustomerId()

  const [
    { data: site, isLoading, isError },
    { data: organisations }
  ] = useQueries({
    queries: [{
      queryKey: ['site', siteId],
      queryFn: () => siteRepository.get(siteId)
    }, {
      queryKey: ['organisations', customerId],
      queryFn: () => organisationRepository.list()
    }]
  })

  const updateSiteMutation = useMutation(data => {
    siteRepository.update(siteId, data)
      .then(() => {
        queryClient.invalidateQueries(['sites'])
        queryClient.invalidateQueries(['site', siteId])
        navigateTo('/organisations/sites')
      })
  })

  useBreadcrumbs([
    { label: 'Manage Organisations', link: '/organisations' },
    { label: 'Manage Sites', link: '/organisations/sites' }
  ])

  usePageTitle('Edit Site', "Update a site's details")

  return <>
    {isLoading
      ? <p className="font-semibold"><LoadingSpinner /> Fetching data...</p>
      : (isError
        ? <p>ERROR</p>
        : <PanelContainer className='max-w-[600px]'>
          <Form onSubmit={handleSubmit(data => updateSiteMutation.mutate(data))}>
            <FormGroup className='flex flex-col gap-1' label='Organisation' type='vertical'>
              <Dropdown
                options={(organisations ?? []).map(org => ({
                  text: org.name,
                  value: org.id
                }))}
                {...register('organisation_id', { required: true })}
                defaultValue={site.organisation_id}
                hasBorder
              />
            </FormGroup>
            <FormGroup className='flex flex-col gap-1' label='Site Name' type='vertical'>
              <Input
                {...register('name', { required: true })}
                defaultValue={site.name}
              />
            </FormGroup>
            <FormGroup className='flex flex-col gap-1' label='Address' type='vertical'>
              <Input
                {...register('location', { required: true })}
                defaultValue={site.location}
              />
            </FormGroup>
            <FormGroup className='flex' label='Third Party' type='vertical'>
              <Checkbox {...register('is_third_party')} defaultChecked={site.is_third_party} />
            </FormGroup>
            <FormGroup className='flex flex-col gap-1' label='Zone Size' type='vertical'>
              <Dropdown
                options={SiteDistances.options}
                defaultValue={site.zone_size}
                {...register('zone_size', { required: true })}
                hasBorder
              />
            </FormGroup>
            <FormGroup className='flex flex-col gap-1' label='Latitude' type='vertical'>
              <DecimalInput
                {...register('latitude', { required: true })}
                defaultValue={site.latitude}
              />
            </FormGroup>
            <FormGroup className='flex flex-col gap-1' label='Longitude' type='vertical'>
              <DecimalInput
                {...register('longitude', { required: true })}
                defaultValue={site.longitude}
              />
            </FormGroup>
            <Button
              className='items-center justify-center'
              type='submit'
              loading={updateSiteMutation.isLoading}
            >
              Update
            </Button>
          </Form>
        </PanelContainer>
      )
    }
  </>
}
