import axios from 'axios'
import { useNavigate } from 'react-router'
import useAuth from './useAuth'

export default function useRepository(repository) {
  const { setUser, isAdminUser } = useAuth()
  const navigateTo = useNavigate()

  // bootstrap axios client
  const client = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
    headers: {
      accept: 'application/json',
      'Access-Control-Allow-Credentials': true
    }
  })

  // The request interceptor is triggered before a request is sent.  We can use
  // it here to inject the current customer id if the logged-in user is an Admin
  client.interceptors.request.use(
    request => {
      if (isAdminUser) {
        request.headers['X-Customer-Id'] = localStorage.getItem('x-customer-id')
      }
      return request
    },
    error => error
  )

  // The response interceptor is triggered before the .then of a Promise is
  // invoked so we can catch a 401 or 419 message (session timout or
  // unauthenticated) and redirect to the login page
  client.interceptors.response.use(
    response => response,
    error => {
      // using the AuthContext to clear the user object will automatically reset
      // the app to the login page.
      // If this becomes a common occurence we'll need to implement some sort
      // of message to show on the login page so the user understands what's
      // happened
      if ([401, 419].includes(error.response?.status)) {
        setUser(null)
        navigateTo('/login')
        // Axios will expose itself in the console unless we throw an error here
        return Promise.reject(new Error('Unauthenticated'))
      }

      // Pass on the error to be dealt with in the client
      return Promise.reject(error)
    }
  )

  return repository(client)
}
