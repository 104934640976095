
import Button from '../Form/Button'
import Checkbox from '../Form/Checkbox'
import FormGroup from '../Form/FormGroup'
import PageMainForm from '../PageMainForm'
import PeriodPicker from '../PeriodPicker'

export default function UsageReportForm({
  setValue,
  handleSubmit,
  register,
  isLoading,
  hasSearched
}) {
  return (
    <PageMainForm onSubmit={handleSubmit}>
      <PeriodPicker label='Date Range' fromDateName='periodStartDate' toDateName='periodEndDate' setValue={setValue} initialFromValue='2023-01-01' minDate='2022-11-11' />
      <FormGroup label='Exclude assets marked as offline' labelClassName='w-full max-w-[22rem]'>
        <Checkbox {...register('excludeOffline')} />
      </FormGroup>
      <FormGroup label={true} labelClassName='w-full max-w-[0rem]'>
        <Button type='submit' loading={isLoading && hasSearched} className='self-start'>Generate Insights</Button>
      </FormGroup>
    </PageMainForm>
  )
}
