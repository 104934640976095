import DashboardCard from '../../Card/DashboardCard'

export default function AssetsByOrganisationCard({ value, description, url, colour }) {
  return (
    <DashboardCard
      value={value}
      description={description}
      backgroundColour='bg-white'
      valueColour={colour}
      textColour='text-black'
      buttonColour='bg-shady-lady'
      url={url}
    />
  )
}
