import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { matchPath, useLocation, useNavigate } from 'react-router'
import LoadingPage from '../pages/LoadingPage'

export const AuthContext = React.createContext()

export default function AuthProvider({ children }) {
  const navigateTo = useNavigate()
  const { pathname } = useLocation()
  const [user, setUser] = useState(null)

  const unrestrictedRoutes = [
    '/login',
    '/forgotten-password',
    '/map'
  ]
  const isRestrictedRoute = unrestrictedRoutes.filter(path => matchPath(path, pathname)).length === 0

  /**
   * As a user needs to be logged in to use this app, we can make this check
   * here to ensure we forward to the login page if we're not logged in and
   * the page is restricted.
   */
  useEffect(() => {
    // We can't use the AuthRepository here because that depends on this Context
    // so it would be a circular dependency which is very very bad
    axios
      .get(process.env.REACT_APP_API_URL + '/login', {
        withCredentials: true,
        headers: {
          accept: 'application/json',
          'Access-Control-Allow-Credentials': true
        }
      })
      .then(response => response.data?.user)
      .then(user => setUser(user))
      .catch(() => {
        if (isRestrictedRoute) {
          navigateTo('/login')
        }
      })
  }, [isRestrictedRoute, navigateTo, pathname])

  const options = {
    user,
    setUser,
    isAdminUser: user?.is_admin === true,
  }

  return (
    <AuthContext.Provider value={options}>
      {(isRestrictedRoute && user === null)
        ? <LoadingPage />
        : <>{ children }</>
      }
    </AuthContext.Provider>
  )
}
