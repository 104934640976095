import SiriusSenseLogo from '../SiriusSenseLogo'
import ReportsItem from './SidebarItems/ReportsItem'
import OrganisationsItem from './SidebarItems/OrganisationsItem'
import AdminItem from './SidebarItems/AdminItem'
import useAuth from '../../Hooks/useAuth'
import DashboardItem from './SidebarItems/DashboardItem'
import useShowNav from '../../Hooks/useShowNav'

export default function Sidebar() {
  const { isAdminUser } = useAuth()
  const { showNav } = useShowNav()

  return (
    <div className={`flex flex-col items-center bg-fiord ${!showNav ? 'hidden' : ''}`}>
      <div className='p-4'>
        <a href='https://siriusas.co.uk' target='_blank' rel='noreferrer'>
          <SiriusSenseLogo colourClass='text-white-smoke' />
        </a>
      </div>

      <ul className='mt-16 space-y-4 w-full text-sm font-medium list-none text-mischka'>
        <DashboardItem />
        <ReportsItem />
        <OrganisationsItem />
        {isAdminUser && <AdminItem />}
      </ul>
    </div>
  )
}
