import { forwardRef } from 'react'

const Input = ({
  className = '',
  type = 'text',
  value = '',
  onChange = () => {},
  placeholder = null,
  ...props
},
ref) => {
  const classList = `
    border
    w-full
    p-2
    rounded-[4px]
    border-opacity-30
    border-shady-lady
    ${className}
  `

  return (
    <input
      ref={ref}
      className={classList}
      type={type}
      onChange={onChange}
      placeholder={placeholder}
      {...props}
    />
  )
}

export default forwardRef(Input)
