export default function DashboardCardBody({
  textColour = 'text-black',
  description = null,
  percentage = null,
}) {
  return (
    <div className={`text-xs ${textColour} h-full`}>
      {description}
      <br />
      {percentage && `(${percentage}%)`}
    </div>
  )
}
