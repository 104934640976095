import DashboardCard from '../../Card/DashboardCard'

export default function PolledOver30Days({ value, percentage }) {
  return (
    <DashboardCard
      value={value}
      description='Polled more than 30 days ago'
      backgroundColour='bg-danger'
      valueColour='text-white'
      textColour='text-white'
      buttonColour={'bg-white'}
      percentage={percentage}
      url='/reports/search?pingOver30Days=true'
    />
  )
}
