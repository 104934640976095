export default function UserRepository(client) {
  const list = () => (
    client.get('/api/users')
      .then(response => response.data?.data)
  )

  const create = (data) => (
    client.post('/api/users', data)
  )

  const get = (userId) => (
    client.get(`/api/users/${userId}`)
      .then(response => response.data?.data)
  )

  const update = (userId, data) => (
    client.patch(`/api/users/${userId}`, data)
  )

  return {
    list,
    create,
    get,
    update
  }
}
