import { BuildingStorefrontIcon, UserGroupIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router'
import Button from '../../components/Form/Button'
import useBreadcrumbs from '../../Hooks/useBreadcrumbs'
import usePageTitle from '../../Hooks/usePageTitle'

export default function AdminPage() {
  const navigateTo = useNavigate()

  useBreadcrumbs([
    { label: 'Admin', link: '/admin' }
  ])

  usePageTitle('Admin', 'Administrator dashboard')

  return <>
    <div>
      <Button className='flex gap-3' onClick={() => navigateTo('/admin/users')}>
        <UserGroupIcon className='w-6 h-6'/>
        User Management
      </Button>
    </div>

    <div>
      <Button className='flex gap-3' onClick={() => navigateTo('/admin/customers')}>
        <BuildingStorefrontIcon className='w-6 h-6'/>
        Customer Management
      </Button>
    </div>
  </>
}
