import BatteryFailYear from './BatteryFailYear'
import PanelContainer from '../PanelContainer'

export default function BatteryFailPeriod({ batteryFailPeriod }) {
  return (
    <PanelContainer className='w-full max-w-[970px] py-4 px-4'>
      <div className="flex gap-x-8 text-center flex-wrap font-light">
        <div className="flex flex-col mr-6 text-left">
          <div className="font-bold text-xl">Forecasted Fail Periods</div>
        </div>
        <div className="flex flex-col px-6 grid-cols-3 text-center flex-wrap font-light">
          {Object.entries(batteryFailPeriod).map(([year, months]) => {
            const countTotal = months.reduce((total, monthObj) => {
              const count = Object.values(monthObj)[0]
              return total + count
            }, 0)

            return (
              <BatteryFailYear
                key={year}
                year={year}
                countTotal={countTotal}
                months={months}
              />
            )
          })}
        </div>
      </div>
    </PanelContainer>
  )
}
