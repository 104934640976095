import { createContext } from 'react'
import convertToBoolean from '../functions/convertToBoolean'

export const FeatureFlagContext = createContext({
  getEnabledValue: (flag) => false,
})

export default function FeatureFlagProvider({ children }) {
  // When creating new feature flags, they must be added to this list to be usable
  const flags = {
    watchlist: process.env.REACT_APP_FEATURE_WATCHLIST_ENABLED ?? false
  }

  function getEnabledValue(flag) {
    return convertToBoolean(flags[flag])
  }

  return (
    <FeatureFlagContext.Provider value={{ getEnabledValue }}>
      {children}
    </FeatureFlagContext.Provider>
  )
}
