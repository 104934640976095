import { useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import useAuth from '../../Hooks/useAuth'
import useCustomerId from '../../Hooks/useCustomerId'
import { UserCircleIcon } from '@heroicons/react/24/outline'
import NavbarInput from './NavbarInput'

export default function CustomerViewDropdown() {
  const { user: { customers } } = useAuth()
  const { customerId, setCustomerId } = useCustomerId()
  const queryClient = useQueryClient()

  useEffect(() => {
    // Invalidate ALL queries because we've just changed the context for which
    // customer we're viewing as.  This needs to be done here because the
    // Context that stores the customer id is outside of the query provider
    queryClient.invalidateQueries()
  }, [customerId])

  const onChangeDropdown = (e) => {
    setCustomerId(e.currentTarget.value)
  }

  return (
    <NavbarInput icon={<UserCircleIcon className='w-5 h-5' />}>
      <select
        className='outline-none cursor-pointer w-full py-[1px]'
        onChange={onChangeDropdown}
        value={customerId}
      >
        {customers.map((customer, key) => {
          return (
            <option key={`dropdown-${key}`} value={customer.id}>{customer.name}</option>
          )
        })}
      </select>
    </NavbarInput>
  )
}
