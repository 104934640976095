import useRepository from '../Hooks/useRepository'
import DashboardRepository from '../Repository/DashboardRepository'
import { useQuery } from '@tanstack/react-query'
import LoadingSpinner from '../components/LoadingSpinner'
import CardRow from '../components/Card/CardRow'
import AllAssetsCard from '../components/Dashboard/Cards/AllAssetsCard'
import PolledWithin7DaysCard from '../components/Dashboard/Cards/PolledWithin7DaysCard'
import PolledWithin30DaysCard from '../components/Dashboard/Cards/PolledWithin30DaysCard'
import PolledOver30DaysCard from '../components/Dashboard/Cards/PolledOver30DaysCard'
import InZoneCard from '../components/Card/cardVariants/InZoneCard'
import OutOfZoneCard from '../components/Card/cardVariants/OutOfZoneCard'
import OfflineCard from '../components/Dashboard/Cards/OfflineCard'
import LastViewedAssetCard from '../components/Card/cardVariants/LastViewedAssetCard'
import calcPercentage from '../functions/calcPercentage'
import useBreadcrumbs from '../Hooks/useBreadcrumbs'
import WatchListCard from '../components/Card/cardVariants/WatchListCard'

export default function DashboardPage() {
  const dashboardRepository = useRepository(DashboardRepository)

  const lastViewedAsset = dashboardRepository.getLastViewedAssetName()

  const { data, isLoading, isError } = useQuery({
    queryKey: ['dashboard'],
    queryFn: () => dashboardRepository.loadData()
  })

  useBreadcrumbs([
    { label: 'Summary', link: '/' }
  ])

  return <>
    { isLoading
      ? <p className='flex justify-center items-center'><LoadingSpinner />Loading...</p>
      : (isError
        ? <p>An error occured, please refresh the page and try again</p>
        : <>
          <CardRow>
            <AllAssetsCard value={data.all_assets} />
            <PolledWithin7DaysCard
              value={data.polled_within_7_days}
              percentage={calcPercentage(data.all_assets, data.polled_within_7_days)}
            />
            <PolledWithin30DaysCard
              value={data.polled_within_30_days}
              percentage={calcPercentage(data.all_assets, data.polled_within_30_days)}
            />
            <PolledOver30DaysCard
              value={data.polled_after_30_days}
              percentage={calcPercentage(data.all_assets, data.polled_after_30_days)}
            />
            <OfflineCard
              value={data.offline}
              percentage={calcPercentage(data.all_assets, data.offline)}
            />
          </CardRow>

          <CardRow>
            <InZoneCard
              value={data.in_zone}
              percentage={calcPercentage(data.all_assets, data.in_zone)}
              showIcon
            />
            <OutOfZoneCard
              value={data.out_of_zone}
              percentage={calcPercentage(data.all_assets, data.out_of_zone)}
              showIcon
            />
          </CardRow>

          <CardRow>
            <WatchListCard
              value={data.watchlist}
              showIcon
            />
            { lastViewedAsset &&
              <LastViewedAssetCard
                value={lastViewedAsset.name}
                showIcon
                url={`/reports/asset-history/${lastViewedAsset.id}`}
              />
            }
          </CardRow>
        </>
      )
    }
  </>
}
