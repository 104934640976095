
// Returns "today", "yesterday", or "x days ago"
export default function createDateDiffText(diff) {
  if (diff === 0) {
    return 'Today'
  }
  if (diff === 1) {
    return 'Yesterday'
  }
  return (`${diff} days ago`)
}
